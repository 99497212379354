/*
 * @Descripttion: CardTable表格的store
 * @version: 
 * @Author: renyjk
 * @Date: 2020-06-29 13:41:41
 * @LastEditors: bbq
 * @LastEditTime: 2021-01-15 14:11:38
 */

import { TabsStore } from '../TabsTable/store';
import { hookFactory } from '@platform/template';
import { CONFIG, utils } from '@platform/table-core';
const { isArray, isWrongFalse, isObject } = utils;
const { ROW_STATUS } = CONFIG;

//-----------行操作API-----------//
//---- cardTable 所有修改数据的 API, 在修改数据前，都要对store进行缓存，因为单据都有取消按钮
/******
设置缓存数据的时机: 
    setSaveData 没有判断  直接缓存
    updateTableData： 没有判断 直接缓存
    //设置完所有数据后，就对数据进行了缓存
    //更新数据时，领域判断是否同步缓存
    setTableData： 根据条件判断
    updateDataByRowId  根据条件判断
    updateDataByIndexs  根据条件判断
    updateDiffDataByRowId 根据条件判断

重置缓存数据时，将数据还原   
    //取消时，领域调用api重置数据
    resetTableData
*****/

export class MetaStore extends TabsStore {

    constructor(props) {
        super(props);

        // 合并一下属性  不然会导致  get 方法取不到值 by bbqin
        this.store = {
            ...this.store,
            tableSwitch: {
                ...this.store.tableSwitch,
                ListViewVisible: false, //行展开

            },

            //记录当前激活状态表格的区域编码
            activeTableCode: '',

            //行展开
            expandedRowKeys: [],
            //侧拉
            tableSideBox: {
                sideBoxVisible: false,
                status: 'edit',
                index: -1,
                record: null,
            },
            //全部展开收起

            //孙表数据
            // eslint-disable-next-line object-curly-newline
            grandTable: {
                // rowid1: {
                //     code1:{ rows:[] },
                //     code2:{ rows:[] },
                //     code3:{ rows:[] }
                // },
                // rowid2:{
                //     code1:{ rows:[] },
                //     code2:{ rows:[] },
                // }
            },

            //存孙表store引用
            grandTableStore: {},
            grandTableIds: [],

        }
        // console.log(this.store, this.tableSwitch);
    }

    /**
     * 行展开视图控制
     * @param {*} record  行数据
     */
    toggleRowView = record => {
        //let cardTableStore = this.TableStore[tableId];
        let expandedRowKeys = this.getStore('expandedRowKeys');

        //console.log("expandedRowKeys:---", expandedRowKeys)

        let indexNum = expandedRowKeys.indexOf(String(record.rowid));
        if (indexNum != -1) {
            expandedRowKeys.splice(indexNum, 1);
            record.expandRowStatus = false;
        } else {
            expandedRowKeys.push(String(record.rowid));
            record.expandRowStatus = true;
        }
        if (expandedRowKeys.length > 0) {
            //this.myTable[tableId].state.table.showXScroll = false;
        } else {
            //this.myTable[tableId].state.table.showXScroll = true;
        }

        //console.log('expandedRowKeys', expandedRowKeys)
        this.setStore('expandedRowKeys', expandedRowKeys, true);
    }

    /**
     * 显示侧拉窗
     * @param {*string}  type      type为add和edit
     * @param {*object} record    行数据
     * @param {*number} index     行index
     * @param {*function} callback 展开回调
     * @param {*function} flag //???  TODO 
     */
    showSideBox = (status, record, index) => {
        //console.log("tableSideBox---执行---")
        if (status === 'add') {
            record = this.addTableRow(index);
            //存旧值逻辑应该放 addTableRow 方法内部
            //this.saveRowsOldValue([record]);
            // 内部用的值应该是normalIndex
            index = this.getViewData().length - 1;
        }
        status = status === 'browse' ? 'browse' : 'edit';
        this.setStore('tableSideBox', {
            ...this.tableSideBox,
            status,
            record,
            index,
            sideBoxVisible: true,
        });
    }

    closeSideBox = () => {
        this.setStore('tableSideBox', {
            ...this.tableSideBox,
            sideBoxVisible: false,
        });
    }

    /*** 全部展开控制 */
    openListView = isShow => {
        this.setStore(['tableSwitch', 'ListViewVisible'], isShow)
    }

    /**
     * 设置孙表数据
     */
    setGrandTableData = ({ rowid, tableId, data, shouldForceUpdate = true, isCache = true, isDiffUpdate = false }) => {
        let tableSideBoxRecord = this.getStore(['tableSideBox', 'record']);
        let grandTableStore = this.getStore('grandTableStore');

        let grandTable = this.get('grandTable');
        if (!grandTable[rowid]) grandTable[rowid] = {};
        if (!grandTable[rowid][tableId]) grandTable[rowid][tableId] = {};

        //全量更新时，补全rowid
        if (data && isArray(data.rows) && !isDiffUpdate) {
            data.rows = data.rows.map(item => {
                item.rowid = item.rowid || this.generateRowKey();
                return item;
            })
        }
        // 如果业务侧传了data  但是没传rows 会导致切换数据不正确
        if (data && !data.rows) {
            console.warn('not get data.rows, maybe grandTableData error');
            data.rows = [];
        }
        //若为差异更新，则
        if (isDiffUpdate) {
            //根据rowid 对比更新孙表数据
            let oldData = grandTable[rowid][tableId].rows || [];
            data.rows = data.rows.map(newRow => {
                // 若该行数据，旧值中已存在，则合并新行和旧行
                if (newRow.rowid) {
                    let index = oldData.findIndex(e => e.rowid === newRow.rowid);
                    if (index !== -1) {
                        newRow.values = {
                            ...oldData[index].values,
                            ...newRow.values,
                        }
                    }
                    //否则为新增行，生成rowid
                } else {
                    newRow.rowid = this.generateRowKey();
                }
                return newRow;
            })
            grandTable[rowid][tableId].rows = data.rows;
        } else {
            //全量更新数据
            grandTable[rowid][tableId] = data;
        }

        //同步侧拉数据
        if (tableSideBoxRecord && tableSideBoxRecord.rowid === rowid && grandTableStore[tableId]) {
            grandTableStore[tableId].setData({ data: grandTable[rowid][tableId].rows, shouldForceUpdate: false })
        }
        this.setStore('grandTable', grandTable, shouldForceUpdate);

        if (isCache) {
            let grandTableData = this.getCache(["grandTableData"]);
            if (!grandTableData) grandTableData = {};
            if (!grandTableData[rowid]) grandTableData[rowid] = {};
            grandTableData[rowid][tableId] = JSON.parse(JSON.stringify(data));
            this.setCache(["grandTableData"], grandTableData);

            //_saveAllData(tableId, null); // TODO 缓存表格初始全数据 zh
        }
    }

    /**
     * 获取所有孙表数据
     */
    getAllGrandData = isDeepCopy => {
        let grandTableIds = this.get('grandTableIds');
        let grandTableStore = this.get('grandTableStore');
        let cacheGrandTable = this.get('grandTable');
        let editRecord = this.get('tableSideBox').record;
        let editRowid = editRecord && editRecord.rowid;
        if (editRowid) {
            let currentGrandTable = {};
            for (let rowid in cacheGrandTable) {
                if (rowid === editRowid) {
                    currentGrandTable[rowid] = {};
                    grandTableIds.forEach(grandCode => {
                        currentGrandTable[rowid][grandCode] = { rows: grandTableStore[grandCode].getAllRows(isDeepCopy) }
                    })
                } else {
                    currentGrandTable[rowid] = cacheGrandTable[rowid];
                }
            }
            return currentGrandTable;
        } else {
            return cacheGrandTable;
        }
    }

    getViewGrandData = view => {
        let grandTableIds = this.get('grandTableIds');
        let grandTableStore = this.get('grandTableStore');
        let cacheGrandTable = this.get('grandTable');
        let editRecord = this.get('tableSideBox').record;
        let editRowid = editRecord && editRecord.rowid;
        if (editRowid) {
            let currentGrandTable = {};
            for (let rowid in cacheGrandTable) {
                if (rowid === editRowid) {
                    currentGrandTable[rowid] = {};
                    grandTableIds.forEach(grandCode => {
                        currentGrandTable[rowid][grandCode] = { rows: grandTableStore[grandCode].getViewData(view) }
                    })
                } else {
                    currentGrandTable[rowid] = cacheGrandTable[rowid];
                }
            }
            return currentGrandTable;
        } else {
            return cacheGrandTable;
        }
    }

    /**
     * 过滤当前子表所有孙表的空行
     *  { 
     *      grandTableCode1: { keys:[], rule='except' },
     *      grandTableCode2: { keys:[], rule='except' },
     *  }
     */
    filterEmptyGrandRows = (granTableConfig = {}, shouldForceUpdate = true) => {
        //孙表所有数据
        let cacheGrandTable = this.get('grandTable');
        let newGrandData = {};

        for (let rowid in cacheGrandTable) {
            let currentRowGrandData = cacheGrandTable[rowid];
            newGrandData[rowid] = {};
            for (let tableCode in currentRowGrandData) {
                let keys = null;
                let rule = 'except';
                if (granTableConfig[tableCode]) {
                    if (isArray(granTableConfig[tableCode].keys)) {
                        keys = granTableConfig[tableCode].keys;
                    }
                    if (isArray(granTableConfig[tableCode].rule)) {
                        rule = granTableConfig[tableCode].rule;
                    }
                }

                let grandTableRows = currentRowGrandData[tableCode].rows;
                newGrandData[rowid][tableCode] = { rows: [] };

                if (isArray(grandTableRows)) {
                    let newRows = [];
                    grandTableRows.forEach((item, index) => {
                        let values = item.values;
                        let valueKeys = Object.keys(values).filter(
                            item => item != "numberindex",
                        );
                        if (isArray(keys) && (rule == "except" || rule == "include")) {
                            valueKeys = valueKeys.filter(val => {
                                return rule == "except"
                                    ? keys.every(key => {
                                        return val !== key;
                                    })
                                    : keys.includes(val);
                            });
                        } else {
                            console.warn("Invalid params ", rule, keys);
                        }

                        let flag = valueKeys.every(one => isWrongFalse(values[one].value));

                        if (!flag) {
                            newRows.push(item);
                        }
                    });

                    newGrandData[rowid][tableCode].rows = newRows;
                }
            }
        }
        this.setStore('grandTable', newGrandData, shouldForceUpdate);
    }

    /**
     * 删除完整的孙表数据中的数据
     */
    deleteGrandData = ({ rowidList, delAll }) => {
        if (delAll) {
            //全部删除
            this.setStore('grandTable', {});
            return;
        }
        //根据rowidList删除数据
        if (isArray(rowidList)) {
            let grandTable = this.get('grandTable');
            rowidList.forEach(rowid => {
                delete grandTable[rowid];
            });
            this.setStore('grandTable', grandTable);
        }
    }

    /**
     * @description: 复写设置表格数据
     * @param {type} 
     * @return: 
     */
    setTableData(data = {}, { isCache = true, isTop = false, shouldForceUpdate = true } = {}) {
        // 将表格全展开重置成false
        // tempData.showListView = false; // TODO
        super.setTableData(data, { isCache, isTop, shouldForceUpdate });
        // TODO 单元格聚焦问题
        // let cell = autoFocus && getCellDom(tableId);
        //     let cellIdentity = cell && getCellIdentity(cell);
        // lastCellItemFocus(cellIdentity);
    }

    /**
     * @description: 设置表格状态
     * @param {type} 
     * @return: 
     */
    setTableStatus(status, { shouldForceUpdate = true } = {}) {
        if (status === 'edit') {
            //编辑态时，收回所有展开行  // TODO
            // closeExpandedRow.call(this, tableId);
        }
        super.setTableStatus(status, { shouldForceUpdate });
    }

    /**
     * @description: 卡片表格的和普通编辑表的 有细微区别
     * @param {type} 
     * @return: 
     */
    addTableRow(index, defaultValue = {}, shouldForceUpdate = true, { autoFocus = false, isAutoAddRow, callback, view } = {}) {
        this.setStore(['tableSwitch', 'ListViewVisible'], false, false);
        return super.addTableRow(index, defaultValue, shouldForceUpdate, { autoFocus, isAutoAddRow, callback, view });
        // TODO  自动聚焦部分  也可在更底层做
    }

    /**
     * @description: 取消编辑  回到上次状态
     * @param {type} 
     * @return: 
     */
    resetTableData() {
        let data = this.getCache(['afterDel']) || this.getCache(['base']) || { rows: [] };

        // TODO  清理一些全局提示
        // clearOneTypeToast('danger');
        this.setTableStatus('browse', { shouldForceUpdate: false });
        this.setTableData(data, { isCache: true, isTop: true, shouldForceUpdate: !this.isUpdatePage });
        // TODO  更新孙表
        // 有孙表的情况
        let grandTableIds = this.get('grandTableIds');
        if (isArray(grandTableIds) && grandTableIds.length > 0) {
            let grandTableCacheData = this.getCache(["grandTableData"]);
            if (isObject(grandTableCacheData)) {
                Object.keys(grandTableCacheData).forEach(rowid => {
                    Object.keys(grandTableCacheData[rowid]).forEach(grandId => {
                        this.setGrandTableData({
                            rowid,
                            tableId: grandId,
                            data: grandTableCacheData[rowid][grandId],
                            shouldForceUpdate: false,
                        });
                    });
                });
            }
        }
    }

    /**
     * @description: 钩子
     * @param {type} 
     * @return: 
     */
    getHooks() {
        return {
            //模板
            ...super.getHooks(),
            toggleRowView: this.toggleRowView.bind(this),
            showSideBox: this.showSideBox.bind(this),
            closeSideBox: this.closeSideBox.bind(this),
            setGrandTableData: this.setGrandTableData.bind(this),
            getAllGrandData: this.getAllGrandData.bind(this),
            getViewGrandData: this.getViewGrandData.bind(this),
            //getRowIdFromPkcode: this.getRowIdFromPkcode,
            deleteGrandData: this.deleteGrandData.bind(this),
            openListView: this.openListView.bind(this),
            openMaxView: this.openMaxView.bind(this),
            setTableData: this.setTableData.bind(this),
            setTableStatus: this.setTableStatus.bind(this),
            addTableRow: this.addTableRow.bind(this),
            resetTableData: this.resetTableData.bind(this),
            filterEmptyGrandRows: this.filterEmptyGrandRows.bind(this),
        };
    }
}

const MetaCardTableStore = hookFactory(MetaStore);

export default MetaCardTableStore;