import React, { Component } from "react";

import { CONFIG, utils } from '@platform/table-core';
import { Item } from "@platform/template";
import { getSysFieldid, getLangCode, getMultiLang, formatNumber, formatDatetime } from "@platform/api";
import "./index.less";
const defaultColor = CONFIG.defaultColor;
const {
    isWrong,
    isObject,
    isArray,
    isNullOrVoid,
    changeTime,
    getRandomPassword,
    formatAcuracy,

} = utils;

export default class SingleRowExpand extends Component {
    constructor(props) {
        super(props);
        this.state = { json: {} };
    }

    UNSAFE_componentWillMount() {
        //加载多语
        let callback = json => {
            this.setState({ json });
        };
        getMultiLang({ moduleId: "table_cardTable", callback });
    }

    content = (metaItems, width, record) => {
        //展开区域高度动态计算，解决操作列固定，展开行时出现位置错乱问题
        let expandStyleData = this.expandLayout(width);
        let itemWidth = expandStyleData.expandItemWidth;
        let col = expandStyleData.col;
        let gridNum = 0;

        return metaItems.map((item, index) => {
            let {
                attrcode: ICode,
                itemtype: IType,
                scale: IScale,
                languageMeta: LanguageMeta,
                color,
            } = item;
            let [values, value, display, scale] = [record.values];
            // 如果有这个键取这个键的value值，否则为null
            value = isObject(values[ICode]) && values[ICode].value;
            display = isObject(values[ICode]) ? values[ICode].display : null;
            scale = isObject(values[ICode])
                ? !isWrong(values[ICode].scale) && values[ICode].scale != "-1"
                    ? +values[ICode].scale
                    : +IScale || 0
                : +IScale || 0;
            // 浏览态全展开的label颜色
            color = defaultColor.includes(color) ? "" : color;
            let isLast = index + 1 === metaItems.length;
            if (index != 0 && item.isnextrow) {
                let currentrowBlankGrid = col - (gridNum % col);
                gridNum += currentrowBlankGrid;
                return (
                    <div
                        className="expanded-row-item"
                        style={{
                            float: "left",
                            width: `${itemWidth * currentrowBlankGrid}%`,
                            height: "28px",
                        }}
                    />
                );
            }
            let newWidth = itemWidth;
            if (item.colnum && item.colnum > 1) {
                if (item.colnum >= col) {
                    newWidth *= col;
                    gridNum += col;
                } else {
                    let num = Number(item.colnum);
                    newWidth *= num;
                    gridNum += num;
                }
            } else {
                gridNum++;
            }
            return (
                <div
                    fieldid={getSysFieldid(item.attrcode)}
                    className="expanded-row-item"
                    key={index}
                    style={{
                        float: "left",
                        width: `${newWidth}%`,
                        marginBottom: isLast && "5px",
                    }}
                >
                    <div style={{ color }} className="expanded-row-item-label">
                        {`${item.label}:`}
                        <span className="expanded-row-item-label-colon" />
                    </div>
                    <div className="expanded-row-item-control">
                        <div className="expanded-row-item-control-wrapper">
                            <span>
                                {this.handleBrowse(
                                    IType,
                                    display,
                                    value,
                                    scale,
                                    ICode,
                                    LanguageMeta,
                                    values,
                                    item && item.datatype,
                                    item && item.options,
                                    item && item.multiple,
                                    record,
                                )}
                            </span>
                        </div>
                    </div>
                </div>
            );
        });
    };

    handleBrowse = (
        IType,
        display,
        value,
        scale,
        attrcode,
        languageMeta,
        values,
        datatype,
        options,
        multiple,
        record,
    ) => {
        let json = this.state.json;
        let { mainCode, tabsStore, rowIndex } = this.props
        let mainStore = tabsStore[mainCode]
        let initSwitch = mainStore.getStore("tableSwitch");
        let { activeCode } = initSwitch;
        let currentMainCode = activeCode;
        let currentStore = tabsStore[currentMainCode]
        // 特殊处理下逻辑型  枚举类型
        if (
            datatype === "32" &&
            ["select", "checkbox", "radio"].includes(IType) &&
            isArray(options) &&
            !display &&
            value !== null &&
            value !== undefined
        ) {
            if (IType === "checkbox" || multiple) {
                let dispalyList = options
                    .filter(item => value.includes(item.value))
                    .map(option => {
                        return option.display;
                    });
                return dispalyList.join(",");
            } else {
                let { display } =
                    options.filter(item => item.value == value)[0] || {};
                return display;
            }
        }

        switch (true) {
            case CONFIG.displayTypes.includes(IType):
                return display || value;
            case CONFIG.boolean.includes(IType):
                return value == "Y" || value == true
                    ? json["cardTable001"]
                    : json["cardTable002"];
            case CONFIG.timeTypes.includes(IType):
                if (IType === "datetimepicker") {
                    return formatDatetime(changeTime(value, "YYYY-MM-DD HH:mm:ss"), 'datetime');
                } else if (IType === "timepicker") {
                    return formatDatetime(value, 'time');
                } else if (IType === "datePickerNoTimeZone") {
                    return formatDatetime(value, 'date');
                }
                return formatDatetime(changeTime(value), 'date');
            case IType === "label":
                return isNullOrVoid(display) ? value : display || value;
            case IType === "number":
                // 表格浏览态加了四舍五入
                return formatNumber(formatAcuracy(value, scale));
            case IType === "password":
                // 密码框浏览态显示***
                return value && getRandomPassword();
            case IType === "residtxt": // 对多语的处理
                let LangCode = getLangCode();
                let loginLang = languageMeta.filter(i => i.languageCode == LangCode);
                if (loginLang.length > 0) {
                    loginLang[0].index === "1" ? (loginLang[0].index = "") : "";
                    if (
                        values[attrcode + loginLang[0].index] &&
                        values[attrcode + loginLang[0].index].value
                    ) {
                        value = values[attrcode + loginLang[0].index].value;
                        return value;
                    }
                    return value;
                }
                return value;
            case IType === "attachment": // 对多语的处理
                let rowKeyValue = record.rowid
                return <Item
                    browse={false}
                    itemtype={IType}
                    attrcode={attrcode}
                    setFieldValue={({ value: val }) => currentStore.setCellValue({ rowKey: 'rowid', rowKeyValue, rowIndex, attrcode, value: val })}
                    getFieldValue={() => currentStore.getCellValue({ rowKey: 'rowid', rowKeyValue, rowIndex, attrcode })}
                    path={[rowKeyValue, attrcode]}
                />
            default:
                return isNullOrVoid(display) ? value : display || value;
        }
    };

    expandLayout = width => {
        let expandItemWidth = 33.333333;
        let col = 3;
        if (width) {
            if (width < 600) {
                expandItemWidth = 100;
                col = 1;
            }
            if (width >= 600 && width < 900) {
                expandItemWidth = 50;
                col = 2;
            }
            if (width >= 900 && width < 1230) {
                expandItemWidth = 33.333333;
                col = 3;
            }
            if (width >= 1230 && width < 1820) {
                expandItemWidth = 25;
                col = 4;
            }
            if (width >= 1820) {
                expandItemWidth = 20;
                col = 5;
            }
        }
        return { expandItemWidth, col };
    };

    render() {
        let { moduleId, mainCode, record, meta } = this.props;
        if (
            !meta.gridrelation ||
            !meta.gridrelation[moduleId] ||
            !meta.gridrelation[moduleId].destBrowseAreaCode) {
            console.error(`没有配置模板，meta.gridrelation.${moduleId}.destBrowseAreaCode值是undefined`)
            return
        }
        let viewId = meta.gridrelation[moduleId].destBrowseAreaCode;

        //字段是否显示可配置
        let metaItems = meta[viewId].items.filter(item => !!item.visible);
        let b = document.querySelector(`#js_cardTable_${mainCode} .u-table-scroll .u-table-body`);
        let width = 0
        if (b) {//兼容dom不存在
            width = b.getBoundingClientRect().width;
            this.tableBodyWidth = width;
        } else {
            width = this.tableBodyWidth || 0
        }

        return (
            //宽度动态计算：表格宽度-固定列宽度
            viewId ?
                <article
                    style={{
                        width: `${width - 35}px`,
                        position: "relative",
                        zIndex: 2,
                    }}
                    //fieldid="expand-area"
                    className="cardTable-view-item"
                >
                    {this.content(metaItems, width, record)}
                </article> : null

        );
    }
}
