import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { CONFIG } from '@platform/table-core';
import { Item, ItemProvider } from "@platform/template";
import { getSysFieldid, getLangCode, isWrong, isObj, isArray, isNullOrVoid, changeTime, getRandomPassword, formatAcuracy, typeFormat, formatNumber, formatDatetime } from '@platform/api';
import './index.less'

const defaultColor = CONFIG.defaultColor;
const expandLayout = width => {
    let expandItemWidth = 33.333333;
    let col = 3;
    if (width) {
        if (width < 600) {
            expandItemWidth = 100;
            col = 1;
        }
        if (width >= 600 && width < 900) {
            expandItemWidth = 50;
            col = 2;
        }
        if (width >= 900 && width < 1230) {
            expandItemWidth = 33.333333;
            col = 3;
        }
        if (width >= 1230 && width < 1820) {
            expandItemWidth = 25;
            col = 4;
        }
        if (width >= 1820) {
            expandItemWidth = 20;
            col = 5;
        }
    }
    return { expandItemWidth, col };
};

@ItemProvider

class AllRowsExpand extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageSize: 0,
            viewStartIndex: 0,
            viewEndIndex: 0,
            DOMStartIndex: 0,
            DOMEndIndex: 0,
            heightOfFirstLine: 0,
            heightOfLastLine: 0,
            json: {},
        };

        let { meta, mainCode, tableDom } = this.props;
        if (!meta.gridrelation ||
            !meta.gridrelation[mainCode] ||
            !meta.gridrelation[mainCode].destBrowseAreaCode) {
            console.error(`没有配置模板，meta.gridrelation.${mainCode}.destBrowseAreaCode值是undefined`)
            return;
        }
        // id
        this.viewId = meta.gridrelation[mainCode].destBrowseAreaCode;
        //模板
        this.metaItems = meta[this.viewId].items
            .filter(item => !!item.visible);
        let width = tableDom.clientWidth;
        let col = expandLayout(width).col;
        // 每一行行高
        this.lineHeight = Math.ceil(this.metaItems.length / col) * 36 + 25;
        // 需要渲染的数据源
        this.scrollHeight = 300;
        this._OFFSET = 10;
    }

    UNSAFE_componentWillMount() {
        this.calculate(this.props);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.mainCode !== this.props.mainCode) {
            let { meta, mainCode, tableDom } = nextProps;
            if (!meta.gridrelation ||
                !meta.gridrelation[mainCode] ||
                !meta.gridrelation[mainCode].destBrowseAreaCode) {
                console.error(`没有配置模板，meta.gridrelation.${mainCode}.destBrowseAreaCode值是undefined`)
                return;
            }
            // id
            this.viewId = meta.gridrelation[mainCode].destBrowseAreaCode;
            //模板
            this.metaItems = meta[this.viewId].items
                .filter(item => !!item.visible);

            let width = tableDom.clientWidth;
            let col = expandLayout(width).col;
            // 每一行行高
            this.lineHeight = Math.ceil(this.metaItems.length / col) * 36 + 25;

            this.calculate(nextProps);
            this.setTableAreaHeight();
        }
    }

    componentDidMount() {
        this.setTableAreaHeight();

    }

    setTableAreaHeight = () => {
        let windowHeight = document.body.clientHeight;
        if (!windowHeight) { return }
        let header = document.querySelector('.cardTable-tabs')
        if (!header) { return }
        let headerHeight = header.clientHeight;
        let tableAreaHeight = windowHeight - headerHeight - 24
        if (this.state.tableAreaHeight !== tableAreaHeight) {
            this.setState({ tableAreaHeight })
        }
    }

    handleScroll = e => {
        e.stopPropagation();
        let { viewStartIndex } = this.state;
        let { viewStartIndex: currentViewStartIndex } = this.getViewIndex();
        if (viewStartIndex !== currentViewStartIndex) {
            this.calculate(this.props);
        }
    };

    getViewIndex = () => {
        let height = this.lineHeight;
        let scrollHeight = this.scrollHeight;
        let DOM = this.getThisDOM();
        let viewStartIndex = Math.floor(DOM.scrollTop / height),
            pageSize = Math.ceil(scrollHeight / height),
            viewEndIndex = viewStartIndex + pageSize - 1;
        return {
            viewStartIndex,
            viewEndIndex,
            pageSize,
        };
    };

    getThisDOM = () => ReactDOM.findDOMNode(this) || { scrollTop: 0 };

    calculate = props => {
        let DOM = this.getThisDOM(),
            { viewStartIndex, viewEndIndex, pageSize } = this.getViewIndex(),
            { DOMStartIndex, DOMEndIndex } = this.getDOMIndex(viewStartIndex, viewEndIndex, props),
            { heightOfFirstLine, heightOfLastLine } = this.getHeightOfFirstAndLastLineByDOMIndex(
                DOMStartIndex,
                DOMEndIndex,
                props,
            );
        let prevScrollTop = DOM.scrollTop;
        this.setState(
            {
                pageSize,
                viewStartIndex,
                viewEndIndex,
                DOMStartIndex,
                DOMEndIndex,
                heightOfFirstLine,
                heightOfLastLine,
            },
            () => {
                DOM.scrollTop = prevScrollTop;
            },
        );
    };

    getHeightOfFirstAndLastLineByDOMIndex = (viewStartIndex, viewEndIndex, props) => {
        let height = this.lineHeight;
        let data = props.data.filter(item => item.status !== '3');
        let heightOfFirstLine = viewStartIndex * height,
            heightOfLastLine = (data.length - viewEndIndex - 1) * height;
        return {
            heightOfFirstLine,
            heightOfLastLine,
        };
    };

    getDOMIndex = (viewStartIndex, viewEndIndex, props) => {
        let data = props.data.filter(item => item.status !== '3');
        let DOMStartIndex = viewStartIndex - this._OFFSET,
            DOMEndIndex = viewEndIndex + this._OFFSET;
        DOMStartIndex < 0 && (DOMStartIndex = 0);
        DOMEndIndex > data.length - 1 && (DOMEndIndex = data.length - 1);
        return {
            DOMStartIndex,
            DOMEndIndex,
        };
    };

    handleBrowse = (
        IType,
        display,
        value,
        scale,
        attrcode,
        languageMeta,
        values,
        datatype,
        options,
        multiple,
        record,
        rowIndex,
    ) => {
        let json = this.props.langJson;
        let { getCellValue, setCellValue } = this.props
        // 特殊处理下逻辑型  枚举类型
        if (
            datatype === "32" &&
            ["select", "checkbox", "radio"].includes(IType) &&
            isArray(options) &&
            !display &&
            value !== null &&
            value !== undefined
        ) {
            if (IType === "checkbox" || multiple) {
                let dispalyList = options
                    .filter(item => value.includes(item.value))
                    .map(option => {
                        return option.display;
                    });
                return dispalyList.join(",");
            } else {
                let { display } =
                    options.filter(item => item.value == value)[0] || {};
                return display;
            }
        }

        switch (true) {
            case CONFIG.displayTypes.includes(IType):
                return display || value;
            case CONFIG.boolean.includes(IType):
                return value == "Y" || value == true
                    ? json["table_yes"]
                    : json["table_no"];
            case CONFIG.timeTypes.includes(IType):
                if (IType === "datetimepicker") {
                    return formatDatetime(changeTime(value, "YYYY-MM-DD HH:mm:ss"), 'datetime');
                } else if (IType === "timepicker") {
                    return formatDatetime(value, 'time');
                } else if (IType === "datePickerNoTimeZone") {
                    return formatDatetime(value, 'date');
                }
                return formatDatetime(changeTime(value), 'date');
            case IType === "label":
                return isNullOrVoid(display) ? value : display || value;
            case IType === "number":
                // 表格浏览态加了四舍五入
                return formatNumber(formatAcuracy(value, scale));
            case IType === "password":
                // 密码框浏览态显示***
                return value && getRandomPassword();
            case IType === "residtxt": // 对多语的处理
                let LangCode = getLangCode();
                let loginLang = languageMeta.filter(i => i.languageCode == LangCode);
                if (loginLang.length > 0) {
                    loginLang[0].index === "1" ? (loginLang[0].index = "") : "";
                    if (
                        values[attrcode + loginLang[0].index] &&
                        values[attrcode + loginLang[0].index].value
                    ) {
                        value = values[attrcode + loginLang[0].index].value;
                        return value;
                    }
                    return value;
                }
                return value;
            case IType === "attachment": // 对多语的处理
                let rowKeyValue = record.rowid
                return <Item
                    browse={false}
                    itemtype={IType}
                    attrcode={attrcode}
                    setFieldValue={({ value: val }) => setCellValue({ rowKey: 'rowid', rowKeyValue, rowIndex, attrcode, value: val })}
                    getFieldValue={() => getCellValue({ rowKey: 'rowid', rowKeyValue, rowIndex, attrcode })}
                    path={[rowKeyValue, attrcode]}
                />
            default:
                return isNullOrVoid(display) ? value : display || value;
        }
    };

    expandedList = (record, index) => {
        let { tableDom } = this.props;
        if (this.viewId) {
            let width = tableDom.offsetWidth;
            let itemWidth = expandLayout(width).expandItemWidth;

            return (
                <div className="card-table-expand-list cf" fieldid={getSysFieldid('list-item')} num={index}>
                    <div>
                        {this.metaItems.map(item => {
                            let {
                                attrcode: ICode,
                                itemtype: IType,
                                scale: IScale,
                                languageMeta: LanguageMeta,
                                color,
                            } = item;

                            let [values, value, display, scale] = [record.values];
                            // 如果有这个键取这个键的value值，否则为null
                            value = isObj(values[ICode])
                                ? typeFormat(values[ICode].value, IType)
                                : null;
                            display = isObj(values[ICode]) ? values[ICode].display : null;
                            scale = isObj(values[ICode])
                                ? !isWrong(values[ICode].scale) && values[ICode].scale != '-1'
                                    ? +values[ICode].scale
                                    : +IScale || 0
                                : +IScale || 0;
                            // 浏览态全展开的label颜色
                            color = defaultColor.includes(color) ? '' : color;
                            return (
                                <div
                                    className="expanded-list-item"
                                    fieldid={getSysFieldid(item.attrcode)}
                                    style={{
                                        float: 'left',
                                        width: `${itemWidth}%`,
                                    }}
                                    key={ICode}
                                >
                                    <div style={{ color }} className="expanded-list-item-label">
                                        {`${item.label}:`}
                                        <span className="expanded-list-item-label-colon" />
                                    </div>
                                    <div className="expanded-list-item-control">
                                        <div className="expanded-list-item-control-wrapper">
                                            <span>
                                                {this.handleBrowse(
                                                    IType,
                                                    display,
                                                    value,
                                                    scale,
                                                    ICode,
                                                    LanguageMeta,
                                                    values,
                                                    item && item.datatype,
                                                    item && item.options,
                                                    item && item.multiple,
                                                    record,
                                                    index,
                                                )}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            );
        }
    };

    render() {
        let { config, langJson, name, maxVisible, tableDom, adaptionHeight, otherAreaHeight = 0 } = this.props;
        let { DOMStartIndex, DOMEndIndex, heightOfFirstLine, heightOfLastLine } = this.state;
        let tableHeight = tableDom.offsetHeight
        let visibleRows = this.props.data.filter(item => item.status !== '3');
        let styleObj = {}
        if (maxVisible) {
            styleObj.height = this.state.tableAreaHeight || '93%'
        } else if (adaptionHeight) {
            let windowHeight = document.body.clientHeight;
            let cardTableArea = document.querySelector('.cardTable-table-area')
            styleObj.maxHeight = 300
            styleObj.height = 300
            if (cardTableArea) {
                let top = cardTableArea.getBoundingClientRect().top;
                let expandHeight = windowHeight - top - 8 - otherAreaHeight;
                if (expandHeight < 210) { expandHeight = 210 }
                styleObj.maxHeight = expandHeight
                styleObj.height = expandHeight
            }
        } else {
            styleObj.maxHeight = `${tableHeight > 300 ? tableHeight : 300}px`
            styleObj.minHeight = `${tableHeight > 300 ? tableHeight : 300}px`
        }
        return (
            <div
                className="lightapp-component-cardTable-view"
                style={styleObj}
                onScroll={this.handleScroll}
            >
                <div
                    className="card-table-expand-wraps"
                    fieldid={getSysFieldid(`${(config && config.fieldid) || name}_list-area`)}

                >
                    {visibleRows.length ? (
                        <div>
                            <div style={{ height: heightOfFirstLine }} />
                            {visibleRows.slice(DOMStartIndex, DOMEndIndex + 1).map((item, index) => (
                                this.expandedList(item, DOMStartIndex + index)
                            ))}
                            < div style={{ height: heightOfLastLine }} />
                        </div>

                    )

                        : (
                            <div className="no-data-li">
                                <div className="no-data-placeholder">
                                    <i className="no-data" />
                                    <span className="no-data-title">{langJson['table0033']}</span>
                                </div>
                            </div>
                        )}
                </div>
            </div>
        );
    }

}

export default AllRowsExpand;

