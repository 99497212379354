/*
 * @Descripttion: CardTable的 Store
 * @version: 
 * @Author: bbq
 * @Date: 2020-06-28 13:37:53
 * @LastEditors: bbq
 * @LastEditTime: 2021-02-21 14:37:50
 */

import { hookFactory } from '@platform/template';
import { MetaStore } from './components/SingleCardTable/store';
import { utils } from '@platform/table-core';
const { isArray } = utils;

class TableStore extends MetaStore {

    constructor(props = {}) {
        super(props);

        // 合并一下属性  不然会导致  get 方法取不到值 by bbqin
        this.store = {
            ...this.store,
            // moduleId
            name: props.name || '',
            // 所有CardTable的store  （目前是指cardTable的store）
            tableStores: {},
            // 所有CardTable的数据 
            // tableDatas: {}, // 不备份了
            // 初始模板
            meta: {},
            // 所有页签的数据 按照页签id区分
            // allTabsData: {}, // 不备份了
            // 所有表格的状态数据
            // allTabsStatus: {},  // 不备份了
            // 多语
            json: {},
            // 当前缺少项的页签集合
            errorTabIds: [],

            // 布局
            layout: 'horizontal',

            currentTab: {
                // 当前状态
                status: 'browse',
                // 当前操作页签
                tabId: '',
                tableId: '',
                // ...
            },
        }

        //console.log('12131', this.getHooks());
    }

    /**
     * @description: 复写
     * @param {type} 
     * @return: 
     */
    getStore(paths) {
        return super.get(paths);
    }

    /**
     * @description: 复写
     * @param {type} 
     * @return: 
     */
    setStore(paths, value, shouldForceUpdate = true, callback) {
        let res = super.set(paths, value);
        shouldForceUpdate && this.forceUpdate(callback);
        return res;
    }

    // 多来源页签的布局
    setLayout(layout, shouldForceUpdate = true) {
        layout = layout || this.getStore(['currentTab', 'layout']);
        this.setStore(['currentTab', 'layout'], layout === 'vertical' ? 'vertical' : 'horizontal', shouldForceUpdate);
    }


    /**
     * @description: 获取模板的属性
     * @param {type} 
     * @return: 
     */
    _getMetaRelation() {
        let { gridrelation = {}, containerrelation = {} } = this.getMeta();
        return { gridrelation, containerrelation };
    }

    /**
     * @description: 获取所有的tabs
     * @param {type} 
     * @return: 
     */
    _getTabs(name) {
        name = name || this.getStore('name');
        let { containerrelation } = this._getMetaRelation();
        return containerrelation[name] || [];
    }

    /**
     * @description: 批量操作子表
     * @param {type} 
     * @return: 
     */
    _batchTabsTable(func, name) {
        name = name || this.getStore('name');
        if (!name) { console.warn('not find name[%s] tableStore ') }

        let { gridrelation, containerrelation } = this._getMetaRelation();

        let tabs = this._getTabs(name);

        //let layout = this.getLayout(name);

        let stores = this.getStore('tableStores');

        console.log(name, tabs, gridrelation, containerrelation, stores);

        // 先简单处理下
        // if (layout === 'horizontal' && isArray(tabs[0])) {
        //     tabs = [tabs[0]]; // 设置全部也不是不行 并不行  data 还没有初始化  如果初始化了 应该可以
        // }

        let res = tabs.map(ids => {
            ids.map(id => {
                if (!id) {
                    return console.warn('invalid id');
                }

                let currentStore = stores[id];

                if (name && id === name) {
                    currentStore = this;
                }

                if (!currentStore) {
                    console.warn(`tableId 为${id} 表格，没有初始化对应的Store，请不要进行批操作。`);
                }

                return currentStore && func(currentStore, id, name, tabs);
            });
        });

        return res;
    }

    // 设置多来源的所有数据
    setTabsTableData(data, shouldForceUpdate = true, name) {

        if (!data) {
            data = {};
        }

        this._batchTabsTable((store, id) => {
            if (!data[id]) {
                console.warn("not find id[%s] data", id);
            }
            if (data[id]) {
                //let activeTableCode = store.getStore('activeTableCode');
                store.setTableData(data[id], {
                    //shouldForceUpdate: shouldForceUpdate //&& id === activeTableCode && //初始化更新时，业务数字无法更新
                    shouldForceUpdate: false
                });
            }

        }, name)

        shouldForceUpdate && this.forceUpdate();
    }

    // 重置所有页签的数据
    resetTabsTableData(shouldForceUpdate = false, name) {
        this._batchTabsTable((store, id) => {
            if (store) {
                store.resetTableData();
            }
        }, name);
        shouldForceUpdate && this.forceUpdate();
    }

    // 获取所有行的数据
    getTabsTableData(name) {
        let res = {};
        this._batchTabsTable((store, id) => {
            if (store) {
                let data = store.getData();
                res[id] = { rows: data };
            }
        }, name);
        console.log(res);
        return res;
    }

    // 设置所有页签（cardTable）的状态
    setTabsTableStatus(status = {}, shouldForceUpdate = true, name, callback) {
        this._batchTabsTable((store, id) => {
            if (status[id]) {
                let activeTableCode = store.getStore('activeTableCode');
                let oldStatus = store.getTableProps('status') || 'browse';
                store.setTableStatus(status[id], { shouldForceUpdate: activeTableCode === id && shouldForceUpdate });
                callback && callback(id, store, oldStatus);
            }
        }, name);
        // shouldForceUpdate && this.forceUpdate();
    }

    // 获取多页签所有的 stores
    getTabsStores(id, name) {
        name = name || this.getStore('name');
        if (!name) { console.warn('not find name[%s] tableStore ', name) }
        let res = Object.assign({}, { [name]: this }, this.getStore('tableStores'));
        return id ? res[id] : res;
    }

    // 添加stores 缓存数据中
    addTabStore(id, store, pattern, shouldForceUpdate = false) {
        let tableStores = this.getStore('tableStores');
        tableStores[id] = store;
        this.setStore('tableStores', tableStores, shouldForceUpdate);
    }

    //设置页签显示隐藏
    setTabsTableShow(mainId, flagObj, shouldForceUpdate = true) {
        let tabs = this._getTabs(mainId);
        let stores = this.getStore('tableStores');
        if (isArray(tabs)) {
            tabs.forEach((item) => {
                //tabs每一项是一个垂直排列的表格
                let mainCode = item[0];
                if (stores[mainCode]) {
                    let hideTabsCode = stores[mainCode].getStore('hideTabsCode');
                    for (let code in flagObj) {
                        if (item.includes(code)) {
                            hideTabsCode[code] = !flagObj[code];
                        }
                    }
                    stores[mainCode].setStore('hideTabsCode', hideTabsCode, shouldForceUpdate);
                }
            })
        }
    }

    //设置当前激活页签
    setTabsTableActive(mainId, activeCode, shouldForceUpdate = true) {
        let tabs = this._getTabs(mainId);
        let stores = this.getStore('tableStores');
        if (isArray(tabs)) {
            tabs.forEach((item) => {
                if (item.includes(activeCode)) {
                    let mainCode = item[0];
                    let tableSwitch = stores[mainCode].getStore('tableSwitch');
                    tableSwitch.activeCode = activeCode;
                    stores[mainCode] && stores[mainCode].setStore('tableSwitch', tableSwitch, shouldForceUpdate);
                }
            })
        }
    }

    //获取当前激活页签
    getTabsTableActive(mainId) {
        let tabs = this._getTabs(mainId);
        let stores = this.getStore('tableStores');
        if (isArray(tabs)) {
            return tabs.map((item) => {
                let mainCode = item[0];
                if (stores[mainCode]) {
                    return stores[mainCode].getStore('tableSwitch').activeCode;
                }
            })
        }
        return [];
    }

    getHooks() {
        return {
            ...super.getHooks(),
            //模板
            // // all
            getStore: this.getStore.bind(this),
            setStore: this.setStore.bind(this),

            setLayout: this.setLayout.bind(this),
            // getLayout: this.getLayout.bind(this),
            setTabsTableData: this.setTabsTableData.bind(this),
            resetTabsTableData: this.resetTabsTableData.bind(this),
            getTabsTableData: this.getTabsTableData.bind(this),
            setTabsTableStatus: this.setTabsTableStatus.bind(this),
            getTabsStores: this.getTabsStores.bind(this),
            addTabStore: this.addTabStore.bind(this),
            setTabsTableShow: this.setTabsTableShow.bind(this),
            setTabsTableActive: this.setTabsTableActive.bind(this),
            getTabsTableActive: this.getTabsTableActive.bind(this),
        };
    }
}

export default hookFactory(TableStore);