import React, { Component, Fragment } from "react";
import ReactDOM from "react-dom";
import { Tooltip, Table, MaxBox, Dropdown, Menu, ScrollElement } from "@platform/base";
import { lib, utils, CONFIG, handleSortcolumns } from "@platform/table-core";
import classnames from "classnames";
import { getSysFieldid, pageTo, getLangCode, PubSub } from "@platform/api";
import AllRowsExpand from "../AllRowsExpand";
import TabHotKey from "../TabHotkey";

const Item = Menu.Item;
import "./index.less";

const {
    formatColumn,
    hotkey,
    selectRow,
    checkCol,
    indexCol,
    errorCol,
    selectable,
    excel,
    total,
    columnSort,
    contextMenu,
    filter,
    hoverContent,
} = lib;
const {
    uuidv4,
    excelPackageData,
    polymerizeComponents,
    isFunction,
    isArray,
    isString,
    isNumber,
    isEmptyValue,
    isObject,
    isUndefined,
    getMetaIsTotal,
    isWrong,
} = utils;

const MetaTable = polymerizeComponents(Table, [
    columnSort,
    formatColumn,
    selectRow,
    errorCol,
    total,
    hoverContent,
    checkCol,
    indexCol,
    filter,
    selectable,
    excel,
    hotkey,
    contextMenu,
], {
    name: 'cardTable',
    type: 'hoc',
    user: 'platform',
});

const { ROW_STATUS } = CONFIG;
const BEFOREAREACOMPLETE = 'beforeAreaChangeComplete';
const AREACOMPLETE = 'areaChangeComplete';

export default class TabsTable extends Component {
    constructor(props) {
        super(props);
        this.state = { isEllipsis: true }
        //当前激活页签
        this.currentMainCode = this.props.mainCode;
        //页签主区域Store
        this.mainStore = this.props.tabsStore[this.props.mainCode];

        // 当前激活页签状态存在主区域store
        let oldSwitch = this.mainStore.getStore("tableSwitch");
        let initSwitch = {
            ...oldSwitch,
            //activeCode: this.props.mainCode,
            activeCode: oldSwitch.activeCode
                ? oldSwitch.activeCode
                : this.props.mainCode,
        };
        this.mainStore.setStore("tableSwitch", initSwitch, false);

        //当前单来源表格Store
        this.currentStore = this.mainStore;
        this.currentStore.setStore("activeTableCode", this.currentMainCode, false);
        this.currentConfig = this.props.tabsConfig[this.currentMainCode] || {};
        // 孙表使用的是TabsTable
        if (this.props.isGrandTable) {
            for (let source of this.props.metaTabs) {
                // if (!props.tabsStore[source].getRef().length) {
                props.tabsStore[source].setRef(this);
                // }
            }
        }

        //是否所有页签都隐藏
        this.isAllTabsHide = true;

        this.currentData = [];

        this.uuid = uuidv4();
        typeof window === 'object' && (window[this.uuid] = this.props.tabsStore);
    }

    componentDidMount() {
        let { grandComponentDidMount } = this.currentConfig;
        isFunction(grandComponentDidMount) && grandComponentDidMount();
    }

    componentWillUnmount() {
        if (this.props.isGrandTable) {
            for (let source of this.props.metaTabs) {
                this.props.tabsStore[source].removeRef(this);
            }
        }
    }

    componentDidUpdate(nextProps, nextState) {
        let { cardTableComponentUpdate } = this.props;
        let { getData } = this.currentStore;
        let data = getData([ROW_STATUS.delete, ROW_STATUS.filter]);
        cardTableComponentUpdate && cardTableComponentUpdate(this.props, data);
    }

    //页签切换
    tabChange = code => {
        // 当前激活页签状态存在主区域store
        let initSwitch = this.mainStore.getStore("tableSwitch");
        let { activeCode } = initSwitch;
        //API调用导致页签改变，视图不变时，不更新
        if (activeCode === code) {
            return;
        }

        initSwitch.activeCode = code;

        let { tabsStore, tabsConfig } = this.props;
        let meta = this.currentStore.getStore("meta");


        let config = {};
        let mainAreaCode = '';
        let currentCode = code;

        //获取当前激活Store
        if (meta) {
            mainAreaCode = this.getMainCode(meta, code);
            this.currentMainCode = mainAreaCode;
            this.currentStore = tabsStore[mainAreaCode];
            config = tabsConfig[mainAreaCode] || {};
        } else {
            this.currentStore = tabsStore[code];
            this.currentMainCode = code;
            // 可以没有配置参数
            config = tabsConfig[code] || {};
            mainAreaCode = code;
        }
        // 调整下更新顺序 避免下面会调离取不到值
        this.currentStore.setStore("activeTableCode", this.currentMainCode, false);
        this.mainStore.setStore("tableSwitch", initSwitch, true);
        const { onTabChange } = config;
        isFunction(onTabChange) && onTabChange(mainAreaCode, currentCode);
    };
    //圆角切换
    tabsAngleToggle = () => {
        //显示隐藏属性存主Store
        // console.log(this.currentMainCode, this.props.mainCode)
        PubSub.publishSync(BEFOREAREACOMPLETE, true);
        let { tabsStore, mainCode, tabsConfig, metaTabs } = this.props;
        let { getStore, setStore } = tabsStore[mainCode];
        let tableSwitch = getStore("tableSwitch");
        tableSwitch.tableVisible = !tableSwitch.tableVisible;
        setStore("tableSwitch", tableSwitch, true);

        //监听当前页签组的全部事件
        for (let code of metaTabs) {
            let { onHeadAngleToggle } = tabsConfig[code];
            isFunction(onHeadAngleToggle) &&
                onHeadAngleToggle(code, tableSwitch.tableVisible);
        }
        setTimeout(() => {
            PubSub.publish(AREACOMPLETE, true);
        }, 0)
    };

    onAllCheckChange = () => {
        let { tabsConfig } = this.props;
        let { selectedChange, onSelectedAll } =
            tabsConfig[this.currentMainCode] || {};
        let {
            setRowsSelected,
            getSelectedRows,
            getCheckInfo,
            getData,
        } = this.currentStore;
        let { checkedAll } = getCheckInfo() || {};
        let oldLen = getSelectedRows(false, 'filter').length;
        checkedAll = !checkedAll;

        setRowsSelected({}, checkedAll, true);

        let newLen = getSelectedRows(false, 'filter').length;

        isFunction(onSelectedAll) &&
            onSelectedAll(this.currentMainCode, checkedAll, getData().length, this.currentStore);
        isFunction(selectedChange) &&
            selectedChange(this.currentMainCode, newLen, oldLen, this.currentStore);
    };

    // 框选等操作
    onBatchSelected(rows = [], hotkey) {
        let { tabsConfig } = this.props;
        let { onBatchSelected } = tabsConfig[this.currentMainCode] || {};

        let {
            getTableProps,
            setRowsSelected,
            getRowProps,
            getTrueRowIndex,
            setTableProps,
        } = this.currentStore;
        let rowKey = getTableProps("rowKey");

        // 清空所有
        setRowsSelected({}, false, false);

        rows.forEach(row => {
            let rowKeyValue = row[rowKey];
            let selected = !getRowProps({ rowKeyValue }, "selected");

            let values = row.values || row,
                isSagaStatus = values && values.saga_status && values.saga_status.value === '1';
            // 错误行禁用
            if (isSagaStatus) {
                return;
            }

            setRowsSelected({ rowKeyValues: rowKeyValue }, selected, false);

            // setTableProps("currentIndex", getTrueRowIndex({ rowKeyValue }), false);
        });
        // 更新
        this.forceUpdate && this.forceUpdate();
        console.log(rows);
        onBatchSelected && onBatchSelected(this.currentMainCode, rows);
    }

    onCheckChange = (record, index, isUnique = false) => {
        let { tabsConfig } = this.props;
        let { selectedChange, onSelected } =
            tabsConfig[this.currentMainCode] || {};

        let {
            getTableProps,
            setRowsSelected,
            getRowProps,
            getSelectedRows,
            getTrueRowIndex,
            setTableProps,
        } = this.currentStore;
        let rowKey = getTableProps("rowKey");
        let rowKeyValue = record[rowKey];
        let selected = !getRowProps({ rowKeyValue }, "selected");
        let oldLen = getSelectedRows(false, 'filter').length;

        if (isUnique) {
            // 清理所有
            setRowsSelected({}, false, false);
        }

        // 特殊情况 如果当前选择 选择行时 依然选中
        if (!selected && isUnique && oldLen > 1) {
            selected = true;
        }
        setRowsSelected({ rowKeyValues: rowKeyValue }, selected);

        setTableProps("currentIndex", selected ? getTrueRowIndex({ rowIndex: index }, 'normal', 'filter') : -1, false);

        let newLen = getSelectedRows(false, 'filter').length;
        // 选中选项的回调函数
        isFunction(onSelected) &&
            onSelected(this.currentMainCode, record, index, selected, this.currentStore);
        isFunction(selectedChange) &&
            selectedChange(this.currentMainCode, newLen, oldLen, this.currentStore);
    };

    //排序回调
    sortChange = (sortParam, sortData) => {
        let sortObj = {};
        let { activeCode } = this.mainStore.getStore("tableSwitch");
        let meta = this.getCurrentMeta(activeCode);
        sortParam.forEach(item => {
            sortObj[item.field] = item;
        });
        // 处理排序多表头
        handleSortcolumns({ columns: meta, sortObj });

        let invisibleData = this.currentStore.getViewData('invisible') || [];
        this.currentStore.setData({ data: [...sortData, ...invisibleData] });
    };

    //右键菜单点击函数
    contextMenuClick = (btnCode, execCommandCopy, param, insertNum, rowIndex, isHotKey = false) => {
        let { getData, getRowOldValue, insertRowsAfterIndex, deleteTableRows, getCache, getArrayMeta, getRows, getViewData } = this.currentStore;
        let { rightMenuClick, onBatchChange } = this.currentConfig;
        let editCode = ["delete", 'insert_up', 'insert_down'];
        let copyCode = ["copy", "copy-row", "copy-column"]
        switch (true) {
            case editCode.includes(btnCode):
                if (isFunction(rightMenuClick)) {
                    //删行、插行、插行并粘贴等右键按钮需要业务适配
                    if (btnCode === 'insert_up') {
                        let rows = Array(insertNum).fill({ values: {} });
                        insertRowsAfterIndex(rows, rowIndex - 1);
                    }
                    if (btnCode === 'insert_down') {
                        let rows = Array(insertNum).fill({ values: {} });
                        insertRowsAfterIndex(rows, rowIndex);
                    }
                    if (btnCode === 'delete') {
                        deleteTableRows({ rowIndexs: rowIndex })
                    }
                    rightMenuClick(btnCode, insertNum, rowIndex);
                }
                break;
            case copyCode.includes(btnCode):
                //选中表头
                let activeHeads = getCache('activeSpecialCells');
                //console.log("表头选中信息：", activeHeads)
                let arrayMeta = getArrayMeta(this.currentMainCode);
                console.log("模板数据arrayMeta", arrayMeta)
                let { activeCode } = this.mainStore.getStore("tableSwitch");
                let currentMeta = this.getCurrentMeta(activeCode);
                let activedCells = getCache("activedCells");

                if (isHotKey && !activedCells && !activeHeads) {
                    return;
                }

                let selectedRows = [];
                if (btnCode === 'copy' || btnCode === 'copy-row') {
                    selectedRows = activedCells && getRows({ rowKeyValues: Object.keys(activedCells) });
                } else if (btnCode === 'copy-column') {
                    selectedRows = getData([ROW_STATUS.delete, ROW_STATUS.filter]);
                }
                isFunction(execCommandCopy) && execCommandCopy(activeHeads, currentMeta, activedCells, selectedRows, arrayMeta);
                break;
            case btnCode === "paste":
                //调用复制粘贴回调事件
                if (isFunction(onBatchChange)) {
                    param = {
                        ...param,
                        langJson: this.props.langJson,
                        rows: getData(),
                        viewData: getViewData(),
                        getRowOldValue: getRowOldValue,
                        tableId: this.currentMainCode,
                        store: this.currentStore,
                    }
                    let data = excelPackageData(param);
                    onBatchChange(data);
                }
                break;
            case btnCode === "insert_paste":
                //插行并粘贴
                if (isFunction(onBatchChange)) {
                    let rows = Array(insertNum).fill({ values: {} });
                    insertRowsAfterIndex(rows, rowIndex);
                    //插入空行
                    param = {
                        ...param,
                        langJson: this.props.langJson,
                        rows: getData(),
                        rows: getViewData(),
                        getRowOldValue: getRowOldValue,
                        tableId: this.currentMainCode,
                        store: this.currentStore,
                    }
                    let data = excelPackageData(param);
                    onBatchChange(data);
                }

                break;
        }
    };

    //获取激活页签meta
    getCurrentMeta = activeCode => {
        let tableMeta = this.currentStore.getStore("meta");
        if (tableMeta && tableMeta[activeCode]) {
            return tableMeta[activeCode].items.get();
        }
        return [];
    };

    getMainCode = (meta, activeCode) => {
        let { metaTabs } = this.props;
        let mainCode = activeCode;
        for (let code of metaTabs) {
            if (meta.gridrelation && meta.gridrelation[code]) {
                let relation = meta.gridrelation[code].tabRelation;
                if (isArray(relation) && relation.includes(activeCode)) {
                    mainCode = code;
                }
            }
        }
        return mainCode;
    };

    //获取当前数据模型
    getCurrentModel = () => {
        let { mainCode, tabsStore, tabsConfig } = this.props;
        //最大化、激活页签、表格显示等页签级功能 状态存 主区域Store
        let initSwitch = this.mainStore.getStore("tableSwitch");
        let { activeCode } = initSwitch;

        this.currentMainCode = activeCode;
        let meta = tabsStore[mainCode].getStore("meta");
        if (meta) {
            this.currentMainCode = this.getMainCode(meta, activeCode);
            tabsStore[this.currentMainCode].setStore("activeTableCode", this.currentMainCode, false);
            this.mainStore.setStore("tableSwitch", initSwitch, false);
        }
        //当前激活页签主区域store和config
        return {
            store: tabsStore[this.currentMainCode],
            config: tabsConfig[this.currentMainCode] || {},
        };
    };

    //根据relation关系 获取页签
    getTabs = (meta, activeCode) => {
        let tabs = [];
        let moreTabs = [];
        let activeName = "";
        if (meta) {
            let { metaTabs } = this.props;
            let hideTabsCode = this.mainStore.getStore("hideTabsCode");
            metaTabs &&
                metaTabs.forEach(item => {
                    let relation = meta.gridrelation && meta.gridrelation[item];
                    if (relation && relation.tabRelation) {//亚军回来再看看改的对不对
                        let tabRelation = relation.tabRelation;
                        tabRelation &&
                            tabRelation.forEach(elem => {

                                if (!hideTabsCode[meta[elem].code] && meta[elem].areaVisible !== false) {
                                    // if (tabs.length === 4) {
                                    //     moreTabs.push({
                                    //         name: meta[elem].name,
                                    //         code: meta[elem].code,
                                    //     });
                                    //     if (meta[elem].code === activeCode)
                                    //         activeName = meta[elem].name;
                                    // } else {
                                    this.isAllTabsHide = false;
                                    tabs.push({
                                        name: meta[elem].name,
                                        code: meta[elem].code,
                                    });
                                    // }
                                }
                            });
                    } else {
                        if (meta[item] && (!hideTabsCode[meta[item].code] && meta[item].areaVisible !== false)) {
                            // if (tabs.length === 4) {
                            //     moreTabs.push({
                            //         name: meta[item].name,
                            //         code: meta[item].code,
                            //     });
                            //     if (meta[item].code === activeCode)
                            //         activeName = meta[item].name;
                            // } else {
                            this.isAllTabsHide = false;
                            tabs.push({
                                name: meta[item].name,
                                code: meta[item].code,
                            });
                            // }
                        }
                    }
                });

            // 没有页签时隐藏表格
            if (tabs.length === 0) {
                this.isAllTabsHide = true;
            }

            return { tabs, moreTabs, activeName };
        }
    };

    //多余5个页签显示更多按钮
    getMoreTabsItem = (moreTabs, activeCode, maincode) => {
        return (
            <Menu
                onSelect={({ key }) => {
                    this.tabChange(key);
                }}
            >
                {moreTabs.map(item => {
                    return (
                        <Item
                            key={item.code}
                            className={classnames("tabs-item", { active: item.code === activeCode })}
                            onClick={this.tabChange.bind(this, item.code)}
                        >
                            <a tabIndex={0}>{item.name}{this.getValidateFailed(maincode, item.code) && <i className="iconfont icon-djgantanhao"></i>}</a>
                            <span className="active-line" />
                        </Item>
                    );
                })}
            </Menu>
        );
    };

    getValidateFailed(mainCode, code) {
        let validateFailed = this.currentStore.getCache(['validateFailed', mainCode, code]);
        // console.log(this.currentStore, validateFailed);
        return validateFailed && Object.keys(validateFailed).length > 0;
    }

    isAreaEditable = (code, meta) => {
        let isEditable = false;
        if (meta && meta[code] && meta[code].items) {
            if (meta[code].items.get().find((e => e.disabled !== true && e.visible === true))) {
                isEditable = true
            }
        }
        return isEditable;
    }

    //创建页签
    createTableTabs = () => {
        let { mainCode, tabsStore, langJson } = this.props;
        //当前激活页签主区域store和config
        let { getStore } = this.currentStore;

        // 页签按钮、行展开、全部展开收起 存当前激活 Store
        let {
            tableHeadLeft,
            tableHead,
            hideSwitch,
            hideMaxBtn = false,
            showCheck,
            hideBrowseCheckbox,
            showEditIcon = true, //是否显示编辑小铅笔
            showTotalNum = true, //是否显示总条数
            initShowTotalNum = true, //数据懒加载,当表格展开后才加载数据，则默认表格总行数为0时不显示，有数据才显示总行数
            showSelectedNum = true, //是否显示已选
            showToggleAngle = true,
        } = this.currentConfig;

        showCheck = isUndefined(hideBrowseCheckbox) ? showCheck : status === "browse" ? !hideBrowseCheckbox[activeCode] : showCheck;

        let { status = "browse" } = getStore("props");

        //获取模板页签配置
        let meta = getStore("meta");

        //最大化、激活页签、表格显示等页签级功能 状态存 主区域Store
        let tableSwitch = this.mainStore.getStore("tableSwitch");
        let { activeCode, tableVisible, maxVisible, ListViewVisible } = tableSwitch;

        if (isUndefined(tableVisible) && meta && meta[mainCode]) {
            let { isunfold = true } = meta[mainCode];
            tableVisible = isunfold;
            tableSwitch.tableVisible = tableVisible;
            this.mainStore.setStore("tableSwitch", tableSwitch, false);
        }

        let { tabs = [], moreTabs, activeName } = this.getTabs(meta, activeCode);
        // tabs 数目来设置样式 UE要求
        const isSingleTab = tabs.length <= 1;

        //let thiscode = this.getMainCode(meta, activeCode);
        //let checkNum = tabsStore[thiscode].getStore('selectedIndex').size;
        let checkNum = isArray(this.currentData) ? this.currentData.filter((e => e.selected === true)).length : 0;

        return (
            !this.isAllTabsHide && <div
                className={classnames("cardTable-tabs", {
                    "hide-cardTable": !tableVisible,
                    "show-cardTable": tableVisible,
                    "tabs-toggle-angle": showToggleAngle,
                })}
                fieldid={getSysFieldid("tabs-area")}
            >
                <TabHotKey
                    tabs={tabs}
                    activeKey={activeCode}
                    wrapperId={"js_cardTable_" + mainCode}
                    headerId={"js_lightTabs_header_" + mainCode}
                    moduleId={mainCode}
                />
                <div
                    className={classnames("tabs-area", { "single-tabs-area": isSingleTab })}
                    id={`js_lightTabs_header_${mainCode}`}
                >
                    {showToggleAngle && <i
                        className="cardTable-tabs-angle"
                        onClick={this.tabsAngleToggle}
                    >
                        <Tooltip
                            placement="top"
                            overlay={tableVisible ? langJson['table-tabs-close'] : langJson['table-tabs-expand']}
                            delayHide={1}
                            delayShow={1000}
                        >
                            <span className="angle-icon-wrapper">
                                <span
                                    className={classnames(
                                        "iconfont icon table-tabs-icon",
                                        {
                                            "icon-right": !tableVisible,
                                            "icon-bottom": tableVisible,
                                        },
                                    )}
                                />
                            </span>
                        </Tooltip>
                    </i>}
                    <ul
                        className={classnames("tabs-wrapper", { "single-tab": tabs.length <= 1 })}
                    >
                        {tabs.map((item, index) => {
                            // 以item.code找到mainCode，，在根据mainCode找到store
                            let maincode = this.getMainCode(meta, item.code);
                            let totalNum = 0;
                            if (!tabsStore || !tabsStore[maincode]) {
                                console.warn(
                                    "not find tabsStore",
                                    tabsStore,
                                    mainCode,
                                );
                            } else if (showTotalNum) {
                                totalNum = tabsStore[maincode].getViewData("filter").length;
                            }

                            let showErrorIcon = this.getValidateFailed(maincode, item.code);
                            let editIconFlag = !isSingleTab && showEditIcon && status === 'edit' && this.isAreaEditable(item.code, meta);

                            return <li
                                key={item.code}
                                className={classnames("tabs-item", {
                                    // 给页签左右切换快捷键用
                                    "use-tab-hotkey": true, 
                                    "single-tab": isSingleTab,
                                    "active": !isSingleTab && item.code === activeCode,
                                    "has-edit-icon": editIconFlag,
                                    "has-error-icon": showErrorIcon,
                                })}
                                onClick={this.tabChange.bind(
                                    this,
                                    item.code,
                                )}
                            >
                                <ScrollElement name={item.code}>
                                    {editIconFlag && <i className='icon iconfont icon-yebj edit-icon'></i>}
                                    <a tabIndex={0}>
                                        {item.name}{(showTotalNum && totalNum === 0 && initShowTotalNum) || (showTotalNum && totalNum !== 0) && <span>{'(' + totalNum + ')'}</span>}
                                        <span className="active-line" />
                                    </a>
                                    {showErrorIcon && <i className="iconfont icon-djgantanhao error-icon"></i>}
                                    {index !== tabs.length - 1 && <span className="item-tap"></span>}
                                </ScrollElement>
                            </li>
                        })}

                        {showCheck && <li key={'selectedNum'} className="tabs-item-selected-num">{showSelectedNum && <span >{langJson && langJson['table0034']}{checkNum}{langJson && langJson['table0035']}</span>}</li>}
                        <div className="cardTable-head-left">
                            {typeof tableHeadLeft === "function" && tableHeadLeft()}
                        </div>
                        <li key={'tabsSpace'} className="tabs-item-space-super"></li>
                        <li key={'button'} className="tabs-item-button-area">
                            <div
                                className={classnames("btns-area", { "hide-btns": !tableVisible })}
                            >
                                <div className="cardTable-btns">
                                    {/*全部展开按钮*/}
                                    {status === "edit" ||
                                        (isFunction(hideSwitch) && !hideSwitch()) ||
                                        (
                                            <Tooltip
                                                placement="top"
                                                overlay={ListViewVisible ? langJson['table-view-list'] : langJson['table-view-card']}
                                                delayHide={1}
                                                delayShow={1000}
                                            >
                                                <span
                                                    className={classnames(
                                                        "icon iconfont head-icon",
                                                        {
                                                            "icon-shituqiehuan": ListViewVisible,
                                                            "icon-shituliebiaoqiehuan": !ListViewVisible,
                                                        },
                                                    )}
                                                    onClick={() => {
                                                        let { openListView } = this.mainStore;
                                                        openListView(!ListViewVisible);
                                                    }}
                                                />
                                            </Tooltip>
                                        )}
                                    {/*最大化按钮*/}
                                    {!hideMaxBtn && (
                                        <Tooltip
                                            placement="top"
                                            overlay={maxVisible ? langJson['table-zoom-normal'] : langJson['table-zoom-max']}
                                            delayHide={1}
                                            delayShow={1000}
                                        >
                                            <span
                                                className={classnames(
                                                    "icon iconfont head-icon",
                                                    {
                                                        "icon-zuixiaohua": maxVisible,
                                                        "icon-zuidahua": !maxVisible,
                                                    },
                                                )}
                                                onClick={() => {
                                                    let { openMaxView } = this.mainStore;
                                                    openMaxView(!maxVisible);
                                                }}
                                            />
                                        </Tooltip>
                                    )}
                                </div>
                                <div className="cardTable-head">
                                    {typeof tableHead === "function" && tableHead()}
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        );
    };

    getColSettiongParam = activeCode => {
        let { mainCode } = this.props;
        let { getStore, getConfig } = this.currentStore;
        let meta = getStore('meta');
        if (meta && meta[mainCode]) {
            let currentMeta = meta[mainCode]
            let config = getConfig()
            return {
                appcode: currentMeta.appcode || pageTo.getAppCode(),
                pagecode: currentMeta.pagecode || pageTo.getPageCode(),
                code: currentMeta.templateCode || meta.code,
                pageid: currentMeta.pageid || meta.pageid,
                areaCode: mainCode,
                areaId: currentMeta.oid,
                noColSetClass: config.noColSetClass,
                colSetCallback: config.colSetCallback,
                tableType: 'cardtable',
                tabName: activeCode,
            }
        } else { return {} }
    }

    updateColumnAfterSetting = (newColumn, areaCode) => {
        let { setMeta, getStore, setStore } = this.currentStore;
        let meta = getStore("meta");
        let key = getStore("key");
        if (newColumn.length && areaCode) {
            let finalColumn = [...meta[areaCode].items];

            let sortIndexArr = [];
            let sortObjArr = [];
            newColumn.map(newItem => {
                meta[areaCode].items.map((oldItem, index) => {
                    if (newItem.attrcode === oldItem.attrcode) {
                        oldItem.width = newItem.width;
                        oldItem.islock = newItem.islock;
                        oldItem.visible = newItem.visible;
                        sortIndexArr.push(index);
                        sortObjArr.push(oldItem);
                    }
                });
            });
            sortIndexArr.sort((a, b) => a - b);
            sortIndexArr.map((item, index) => {
                finalColumn[item] = sortObjArr[index];
            });
            meta[areaCode].items = finalColumn;
            setMeta(meta, areaCode)

            setStore('key', (key + 1))
        }
    }

    onBeforeEvent = async params => {
        //当前激活页签主区域store和config
        let currentModel = this.getCurrentModel();
        this.currentStore = currentModel.store;
        this.currentConfig = currentModel.config;

        let {
            onBeforeEvent,
        } = this.currentConfig;
        let {
            getTrueRowIndex,
            setTableProps,
        } = this.currentStore;

        let {
            record,
            rowKey,
            rowKeyValue,
            rowIndex,
            attrcode,
            column,
            value = {},
            validated,
            event,
            triggerType, // onClick || onFocus
        } = params;

        // 用于解决点击前赋值不上的问题
        setTableProps("currentIndex", getTrueRowIndex({ rowIndex: rowIndex }, 'normal', 'filter'), false);
        setTableProps(
            "currentInfo",
            { record, index: rowIndex },
            false,
        );

        // 处理校验状态
        if (validated === false) {
            let name = this.currentStore.getStore("name");
            let activeCode = this.mainStore.getStore(['tableSwitch', 'activeCode']);
            let validateFailed = this.mainStore.getCache(['validateFailed', name, activeCode]);

            if (validateFailed && validateFailed[rowKeyValue]) {
                let oldLen = Object.keys(validateFailed).length;
                delete validateFailed[rowKeyValue][attrcode];

                if (oldLen > 0 && Object.keys(validateFailed[rowKeyValue]).length === 0) {
                    delete validateFailed[rowKeyValue];
                    this.mainStore.refresh();
                }
            }
        }

        // 检验输入字符长度
        if (
            triggerType === "onFocus" &&
            column.maxlength
        ) {
            event.target.isFlag = false;
            event.target.addEventListener(
                "compositionstart",
                e => {
                    e.target.isFlag = true;
                },
                false,
            );
            event.target.addEventListener(
                "compositionend",
                e => {
                    e.target.isFlag = false;
                    let flag = e.target.isFlag;
                    let val = e.target.defaultValue;
                    // if (!flag && val.length > item.maxlength) {
                    //     // val = val.substring(0, count);
                    //     val = val.substring(0, item.maxlength);
                    //     rows[index].values[item.attrcode].value = val;
                    //     toast({
                    //         color: 'danger',
                    //         title: `${json && json['table_tips']}`,
                    //         // content: `${json && json['table_tips_content']}${item.maxlength / 2}${json && json['table_maxlength_unit']}`
                    //         content: `${json && json['table_tips_content']}${item.maxlength}${json && json['table_maxlength_unit']}`
                    //     });
                    //     e.target && e.target.blur();
                    // }
                },
                false,
            );
            // TODO this.setState({ table: this.state.table });
        }
        // 多语控件都不走这里
        if (column.itemtype !== "residtxt") {
            // onFocus 和value  用于批量更改   zhanghengh 18/6/29 先注释，以后可能有用
            this.currentStore.setCache("oldBatchData", {
                // TODO  转换为过滤后的index
                // 在onchange时缓存列数据的key 和value  用于批量更改   zhanghengh 18/6/29
                batchChangeIndex: rowIndex,
                batchChangeKey: column.attrcode,
                batchChangeValue: value.value,
                batchChangeDisplay: value.display,
                // batchChangeValue: isMulti ? theValue : theValue.value,
                // batchChangeDisplay: isMulti ? null : theValue.display
            });
        } else {
            // 这里批改多语存值可能没用，之后在看一下，先留着
            // 多语批改
            // 这里是多语文本的编辑显示逻辑
            // attrcode字段对应的value是主语言
            // 显示规则是有登陆语言显示登陆语言，登陆语言通过getLangCode获得，没有登陆语言显示主语言，attrcode字段对应的value，主语言语言在没有，就什么也不显示
            // index为1比较特殊 取value[attrcode]
            let loginCode = getLangCode(),
                loginIndex = column.languageMeta.find(e => e.languageCode === loginCode)?.index || '',
                loginValue = value[attrcode + loginIndex] || value[attrcode] || value;

            this.currentStore.setCache("oldBatchData", {
                batchChangeIndex: rowIndex,
                batchChangeKey: column.attrcode,
                batchChangeValue: loginValue.value,
                batchChangeDisplay: null,
            });
        }

        // 有一些操作要放在这里处理 设置行状态等等
        if (isFunction(onBeforeEvent)) {
            return (
                (await onBeforeEvent({
                    ...params,
                    moduleId: this.currentMainCode,
                    currentStore: this.currentStore,
                })) !== false
            );
        }
        return true;
    };

    onAfterEvent = async params => {
        //当前激活页签主区域store和config
        let currentModel = this.getCurrentModel();
        this.currentStore = currentModel.store;
        this.currentConfig = currentModel.config;

        let {
            autoAddRow = {},
            onAfterEvent,
        } = this.currentConfig;
        let {
            getTableProps,
            setCellValue,
        } = this.currentStore;

        let {
            record,
            rowKey,
            rowKeyValue,
            rowIndex,
            attrcode,
            column,
            value = {},
            oldValue = {},
            componentValue,
            event,
            triggerType = "onChange", // onChange || onBlur
            isTriggerModal = false,
        } = params;

        let activeCode = this.mainStore.getStore(['tableSwitch', 'activeCode']);

        // 多语 获取当前的code
        if (
            column.itemtype === "residtxt" &&
            isObject(componentValue)
        ) {
            for (let key in componentValue) {
                if (componentValue[key].current) {
                    // value.value = componentValue[key];
                    // oldValue.value = store.getRowOldValue(record[rowKey], key);
                }
            }
        }

        // 新旧值
        oldValue.value =
            oldValue.value ||
            this.currentStore.getRowOldValue(
                record[rowKey],
                attrcode,
            );

        if (triggerType === "onBlur") {
            /*
            * onBlur编辑后 和 切换控件状态
            * 1、input类型    line   编辑后 + 切状态
            *                model  编辑后
            * 2、uninput类型  line   切状态
            *                model  无编辑后  无切状态
            */

            // 这一段可以考虑去除  输入 类型
            if (isString(value.value)) {
                value.value = value.value.trim();
            }

            // 当数值类型只输入一个'-'的时候就将它赋值成0
            if (
                column.itemtype === "number" &&
                value.value === "-"
            ) {
                value.value = 0;
                this.currentStore.setCellValue({
                    rowKeyValue,
                    attrcode,
                    value,
                });
            }

            // 侧拉里面的状态
            if (
                column.itemtype === "select" &&
                isTriggerModal
            ) {
                this.currentStore.setCellProps(
                    rowKeyValue,
                    attrcode,
                    { open: false },
                );
            }

            if (column.itemtype === "residtxt") {
                if (isTriggerModal) {
                    // 当侧拉的情况下
                    // 多语  登陆语言值 赋值给主语言
                    // tableModeldata.values[item.attrcode] = {
                    //     ...tableModeldata.values[item.attrcode],
                    //     value: val[item.attrcode].value
                    // };
                } else {
                    // 当表体的情况下
                }
                // 多语  登陆语言值 赋值给主语言
                // 主语言没值时，取登录语言的值
                if (!record.values[attrcode]?.value) {
                    let loginCode = getLangCode(),
                        loginIndex = column.languageMeta.find(e => e.languageCode === loginCode)?.index || '',
                        loginValue = componentValue[attrcode + loginIndex] || componentValue[attrcode];
                    setCellValue({ rowKey, rowKeyValue, rowIndex, attrcode, value: loginValue });
                }
            }

            // 多语控件都不走这里
            if (column.itemtype !== "residtxt") {
                // 在onblur时缓存列数据的key 和value  用于批量更改   zhanghengh 18/6/29
                // pageScope.batchData[moduleId] = {
                //     batchChangeIndex: outputIndex,
                //     batchChangeKey: item.attrcode,
                //     batchChangeValue: record.values[item.attrcode] ? record.values[item.attrcode].value : '',
                //     batchChangeDisplay: record.values[item.attrcode] ? record.values[item.attrcode].display : ''
                // };
            } else {
                // 多语批改
                // 这里是多语文本的编辑显示逻辑
                // attrcode字段对应的value是主语言
                // 显示规则是有登陆语言显示登陆语言，登陆语言通过getLangCode获得，没有登陆语言显示主语言，attrcode字段对应的value，主语言语言在没有，就什么也不显示
                // const cellData = record.values[item.attrcode];
                // let value = isObj(cellData) ? (cellData.value ? cellData.value : null) : null;
                // const LangCode = getLangCode();
                // const loginLang = item.languageMeta.filter(i => i.languageCode == LangCode);
                // if (loginLang.length > 0) {
                //     loginLang[0].index === '1' ? (loginLang[0].index = '') : '';
                //     if (
                //         record.values[item.attrcode + loginLang[0].index] &&
                //         record.values[item.attrcode + loginLang[0].index].value
                //     ) {
                //         value = record.values[item.attrcode + loginLang[0].index].value;
                //     }
                // }
                // pageScope.batchData[moduleId] = {
                //     batchChangeIndex: outputIndex,
                //     batchChangeKey: item.attrcode,
                //     batchChangeValue: value,
                //     batchChangeDisplay: null
                // };
            }
        }

        // 设置状态  放在  onAfterEvent 事件之前 避免onAfterEvent 修改record报错
        this.currentStore.setCellProps(
            rowKeyValue,
            attrcode,
            { isEdit: false },
        );

        // 有一些操作要放在这里处理 设置行状态等等

        // 多语 获取当前的code
        if (
            column.itemtype === "residtxt" &&
            isObject(componentValue)
        ) {
            for (let key in componentValue) {
                this.currentStore.saveRowOldValue(
                    record[rowKey],
                    key,
                    componentValue[key].value,
                );
            }
        } else {
            this.currentStore.saveRowOldValue(
                record[rowKey],
                attrcode,
                value.value,
            );
        }

        let next =
            isFunction(onAfterEvent) &&
            onAfterEvent(
                {
                    ...params,
                    moduleId: this.currentMainCode,
                    activeCode: activeCode,
                },
                this.currentStore,
            ) !== false;


        if (!next) {
            console.log("not next to run");
            return false;
        }

        /**
         * 自动增行
         * 增一行的条件：
         * 1、最后一行
         * 2、isAddRow 为true
         * 3、当前单元格值不为空
         */

        let viewLen = this.currentStore.getViewData().length;
        let isSwitch_browseDisabled =
            column.itemtype === "switch_browse" &&
            column.disabled; // 开关且不可编辑
        let validValue = column.itemtype === 'residtxt' ? value : value.value;
        validValue = column.itemtype === 'refer' ? componentValue : value;
        const isEmpty = isEmptyValue(validValue, column.itemtype);
        const tableStatus = getTableProps('status');

        if (
            viewLen == rowIndex + 1 &&
            autoAddRow.enabled &&
            !isSwitch_browseDisabled &&
            !isEmpty &&
            tableStatus === "edit"
        ) {
            // 添加自动增行后的回调
            this.currentStore.addTableRow(
                undefined,
                autoAddRow.defaultValue,
                true,
                {
                    callback: newRow => {
                        autoAddRow.callback && autoAddRow.callback(this.currentMainCode, newRow);
                    },
                    isAutoAddRow: true,
                },
            );
        }
    };

    createContent = () => {
        let {
            mainCode,
            expandedRowKeys,
            expandedRowRender,
            langJson,
            renderItems,
            getHotKeyConfig,
            onCellHyperlinkTo,
            isHorizontal,
            isGrandson,
        } = this.props;
        //当前激活页签主区域store和config
        let currentModel = this.getCurrentModel();
        this.currentStore = currentModel.store;
        this.currentConfig = currentModel.config;

        let {
            fieldid,
            autoAddRow = {},
            onChange,
            onBeforeEvent,
            onAfterEvent,
            onBodyScroll,
            lazyload = true,
            onRowClick,
            onRowDoubleClick,
            rowClassName,
            cellClassName,
            hideBrowseCheckbox,
            onBatchChange,
            onBatchSelected,
            high_selectable = {},
            high_filter = {},
            handleFilterCallBack,
            handleResetCallBack,
            hideTableTabs = false,
            onCellKeyDown,
            multipleRowCell,
            bodyExpandAllInRow = false,
            cancelCustomRightMenu = false, showWidthAndHeightConfig = true,
            addBlankCol,
            sort,
            otherAreaHeight, adaptionHeight, watchSeachHeight, height,
            rightMenuClick,
            customTabs = {},
            focusIndex,
            foldCacheId, //对于没有appcode，pagecode..的表格，做折行缓存的标志
            hideRightMenu = false,
            ...othersConfig
        } = this.currentConfig;
        let {
            getTableProps,
            setCellValue,
            getCellValue,
            getCellProps,
            getStore,
            setStore,
            getData,
            getCheckInfo,
            hasSelected,
            getTrueRowIndex,
            setActiveCell, getActiveCell,
            setTableProps,
            setSelectedIndex,
        } = this.currentStore;

        if (hideRightMenu) {
            cancelCustomRightMenu = true;
            high_selectable.enable = false;
            showWidthAndHeightConfig = false;
        }
        let tableSwitch = this.mainStore.getStore("tableSwitch");
        let { activeCode, tableVisible, maxVisible, ListViewVisible } = tableSwitch;

        let meta = getStore('meta');
        if (isUndefined(tableVisible) && meta && meta[mainCode]) {
            let { isunfold = true } = meta[mainCode];
            tableVisible = isunfold;
            tableSwitch.tableVisible = tableVisible;
            this.mainStore.setStore("tableSwitch", tableSwitch, false);
        }
        let { status = "browse", rowKey } = getStore("props");

        this.currentData = getData([ROW_STATUS.delete, ROW_STATUS.filter]);
        // arrcolumns
        let arrColumns = this.currentStore.getArrayMeta(activeCode);
        let colSettingParam = this.getColSettiongParam(activeCode)
        let isTotal = this.currentConfig.showTotal || getMetaIsTotal(this.currentStore.getArrayMeta(activeCode, false));
        let extraAreaHeight = isNumber(otherAreaHeight) && !Number.isNaN(otherAreaHeight) ? otherAreaHeight : 0;
        let adaption = maxVisible || !!(isUndefined(height) && !maxVisible && isHorizontal && adaptionHeight);
        let tableMaxHeight = !maxVisible && this.currentConfig.height ? this.currentConfig.height : 300;//cardtable默认高度
        let tableHight = !maxVisible && this.currentConfig.height ? { minHeight: this.currentConfig.height } : { minHeight: '60px' }; //cardtable设置自定义高度的
        let showTableArea = tableVisible;
        if (status === 'browse') {
            showTableArea = showTableArea && !ListViewVisible;
        }
        //没有表格，不能全展开，会报错
        if (!this.table) { ListViewVisible = false }

        // focusInex
        focusIndex = focusIndex || getTableProps('focusIndex');

        // 默认开启 框选 high_selectable.enable
        //high_selectable.enable = high_selectable.enable || true;

        // 默认启用快捷键选中 只有快捷键选中行
        if (isUndefined(high_selectable.enable)) {
            high_selectable.enable = true;
            // high_selectable.disabled = isUndefined(high_selectable.disabled) ? 'slide' :
            //     (isArray(high_selectable.disabled) ? high_selectable.disabled : [high_selectable.disabled]);
            // high_selectable.single = high_selectable.single || false;
            // // 不开启
            // high_selectable.selectCell = isUndefined(high_selectable.selectCell) ? false : high_selectable.selectCell;
        }
        // console.log(high_selectable);
        // 是否框选(批选)
        const isBatchSelect = isUndefined(high_selectable.selectCell) ? true : high_selectable.selectCell;
        const haveMeta = this.getCurrentMeta(activeCode).length ? true : false

        // if(status !== 'browse'){
        //     high_selectable.enable = false;
        // }
        
        return (
            <div
                className={classnames("cardTable-wrapper", { "cardTable-close": !tableVisible })}
                id={"js_cardTable_" + mainCode}
                fieldid={getSysFieldid(
                    `${fieldid || mainCode}_table-area`,
                )}
                data-uuid={this.uuid}
            >
                {/*页签区域*/}
                {!hideTableTabs && this.createTableTabs()}

                {
                    isFunction(customTabs[activeCode]) ?
                        /*支持自定义页签内容*/
                        customTabs[activeCode](activeCode) :
                        /*表格区域  hideTableTabs 隐藏是要显示表体内容  不然表格不展示了*/
                        (hideTableTabs || !this.isAllTabsHide) && <div
                            className="cardTable-table-area"
                            ref={dom => {
                                this.table = ReactDOM.findDOMNode(dom);
                            }}
                        >
                            {showTableArea && haveMeta && (
                                <MetaTable
                                    ref={dom => {
                                        this.tableDom = ReactDOM.findDOMNode(dom);
                                    }}
                                    focusIndex={focusIndex}
                                    fieldid={fieldid}
                                    onBodyScroll={onBodyScroll}
                                    lazyload={lazyload}
                                    columns={this.getCurrentMeta(activeCode)}
                                    onCellHyperlinkTo={(record, attrcode, value) => {
                                        onCellHyperlinkTo &&
                                            onCellHyperlinkTo(
                                                record,
                                                attrcode,
                                                value,
                                                this.currentMainCode,
                                            );
                                    }}
                                    scroll={{
                                        x: true,
                                        y: tableMaxHeight,
                                    }}
                                    // 表格body高度设置
                                    bodyStyle={{ ...tableHight }}
                                    langJson={langJson} // 给单元格用
                                    data={this.currentData}
                                    name={this.currentMainCode}
                                    rowKey={rowKey}
                                    status={status}
                                    isTotal={isTotal}
                                    renderItems={renderItems[this.currentMainCode]}
                                    getHotKeyConfig={getHotKeyConfig}
                                    sortBack={sort}
                                    sortChange={this.sortChange} //排序的回调
                                    getTrueRowIndex={(rowKeyValue, index, record) => {
                                        index = this.currentStore.getTrueRowIndex({ rowKeyValue }, 'normal', 'filter');
                                        return index;
                                    }}
                                    setCellValue={params => {
                                        let {
                                            rowKey,
                                            rowKeyValue,
                                            rowIndex,
                                            attrcode,
                                            column,
                                            value = {},
                                            triggerType, // onChange || onSelect ...
                                        } = params;

                                        // 因为timepicker的value特殊，所以特殊转换一下start，有问题在看一下
                                        if (column.itemtype === "timepicker") {
                                            if (value.value && value.value.format) {
                                                value.value = value.value.format(
                                                    "hh:mm:ss",
                                                );
                                            }
                                        }

                                        // 适配多语 //这是最新适配的多语  也可以放在 setCellValue 里  TODO
                                        setCellValue({
                                            rowKey,
                                            rowKeyValue,
                                            rowIndex,
                                            attrcode,
                                            value,
                                            callback: (props, value, rowKeyValue, attrcode) => {
                                                if (props && value.validated === false && (
                                                    (isWrong(props.value) && !isWrong(value.value)) ||
                                                    (isWrong(props.display) && !isWrong(props.display))
                                                )
                                                ) {
                                                    delete value.validated;
                                                    // activeCode
                                                    // name
                                                    let name = this.currentStore.getStore("name");
                                                    let activeCode = this.mainStore.getStore(['tableSwitch', 'activeCode']);
                                                    let validateFailed = this.mainStore.getCache(['validateFailed', name, activeCode]);

                                                    if (validateFailed && validateFailed[rowKeyValue]) {
                                                        let oldLen = Object.keys(validateFailed).length;
                                                        delete validateFailed[rowKeyValue][attrcode];

                                                        if (oldLen > 0 && Object.keys(validateFailed[rowKeyValue]).length === 0) {
                                                            delete validateFailed[rowKeyValue];
                                                            this.mainStore.refresh();
                                                        }
                                                    }
                                                }
                                            },
                                        }); // 这里就是onChange 和 onBlur 的集合

                                        let next =
                                            isFunction(onChange) &&
                                            onChange({ store: this.currentStore, ...params }) !== false;
                                        if (!next) {
                                            return;
                                        }

                                        // 数值类型的实时校验 TODO 需要找轩轩进行适配
                                        // let flag = isInputType
                                        //     ? item.itemtype !== 'number'
                                        //         ? foolValue.target
                                        //             ? foolValue.target.isFlag
                                        //             : false
                                        //         : false
                                        //     : false;
                                        // if (!flag && item.maxlength && isInputType && valueChange && foolValue) {

                                        //     if (!flag && valueChange.length > item.maxlength) {
                                        //         valueChange = rows[index].values[item.attrcode].value;
                                        //         toast({
                                        //             color: 'danger',
                                        //             title: `${json && json['table_tips']}`,
                                        //             // content: `${json && json['table_tips_content']}${item.maxlength / 2}${json && json['table_maxlength_unit']}`
                                        //             content: `${json && json['table_tips_content']}${item.maxlength}${json && json['table_maxlength_unit']}`
                                        //         });
                                        //         foolValue.target && foolValue.target.blur();
                                        //     }

                                        //     rows[index].values[item.attrcode].value = valueChange;
                                        // }

                                        // 老版的批改 TODO  分为多语和非多语两种情况
                                        // 处理单元格变化的数据zhnghengh
                                        // let theValue = handleChangeData(item.itemtype === 'refer', foolValue, valueChange, item);
                                        if (column.itemtype !== "residtxt") {
                                            let isMulti = column.isMultiSelectedEnabled;
                                            this.currentStore.setCache("oldBatchData", {
                                                // TODO  转换为过滤后的index
                                                // 在onchange时缓存列数据的key 和value  用于批量更改   zhanghengh 18/6/29
                                                batchChangeIndex: rowIndex,
                                                batchChangeKey: column.attrcode,
                                                batchChangeValue: value.value,
                                                batchChangeDisplay: value.display,
                                                // batchChangeValue: isMulti ? theValue : theValue.value,
                                                // batchChangeDisplay: isMulti ? null : theValue.display
                                            });
                                        } else {
                                            // 多语批改
                                            this.currentStore.setCache("oldBatchData", {
                                                batchChangeIndex: rowIndex,
                                                batchChangeKey: column.attrcode,
                                                batchChangeValue: value.value,
                                                batchChangeDisplay: null,
                                            });
                                        }

                                        // 编辑关联项  放在 onAfterEvent 中

                                        // 把status置为1，标识修改     状态不为1的不动 (比如用户自己set但是状态为2的)
                                        let rowStatus = this.currentStore.getRowProps(
                                            { rowKeyValue },
                                            "status",
                                        );
                                        if (rowStatus == ROW_STATUS.origin) {
                                            this.currentStore.setRowProps(
                                                { rowKeyValue },
                                                { status: ROW_STATUS.edit },
                                            );
                                        }
                                    }}
                                    getCellProps={getCellProps}
                                    getCellValue={getCellValue}
                                    tableId={this.currentMainCode}
                                    store={this.currentStore}
                                    expandedRowKeys={expandedRowKeys || []}
                                    expandedRowRender={expandedRowRender}
                                    //复选框
                                    checkInfo={getCheckInfo()}
                                    onAllCheckChange={this.onAllCheckChange}
                                    onCheckChange={(record, index) => this.onCheckChange(record, index)}
                                    hasSelected={({
                                        rowKey,
                                        rowKeyValue,
                                        rowIndex,
                                    }) => {
                                        return hasSelected(rowKeyValue);
                                    }}
                                    setSelectedIndex={(
                                        { record, rowKey, rowIndex },
                                        e,
                                    ) => {
                                        // 为了避免和新开发的行框选点选区域选事件冲突
                                        if (
                                            high_selectable &&
                                            high_selectable.enable &&
                                            e &&
                                            (e.ctrlKey || e.shiftKey || e.metaKey)
                                        ) {
                                            return;
                                        }
                                        let rowKeyValue = record[rowKey];
                                        let values = record.values || record;
                                        // 错误行禁用
                                        if (values && values.saga_status && values.saga_status.value === '1') {
                                            return;
                                        }
                                        //点击行即勾选复选框
                                        if (status !== 'edit' && (isUndefined(hideBrowseCheckbox)
                                            ? this.currentConfig.showCheck
                                            : status === "browse"
                                                ? !hideBrowseCheckbox[activeCode]
                                                : this.currentConfig.showCheck) && !record.disabled) {
                                            this.onCheckChange(record, rowIndex, true);
                                        } else {
                                            let isSelected = hasSelected(rowKeyValue);
                                            !isSelected && setSelectedIndex([], 'clear');
                                            !isSelected && setSelectedIndex([{ rowKey, rowKeyValue, rowIndex, pattern: 'add' }]);
                                        }
                                    }}
                                    //业务事件
                                    onCellKeyDown={(record, index, attrcode, column, e) => {
                                        onCellKeyDown &&
                                            onCellKeyDown(
                                                this.currentMainCode,
                                                record,
                                                index,
                                                attrcode,
                                                column,
                                                this.currentStore,
                                                e,
                                            );
                                    }}
                                    onBeforeEvent={this.onBeforeEvent}
                                    onAfterEvent={this.onAfterEvent}
                                    onRowClick={(record, index, e) => {
                                        // 为了避免和新开发的行框选点选区域选事件冲突
                                        if (
                                            high_selectable &&
                                            high_selectable.enable &&
                                            e &&
                                            (e.ctrlKey || e.shiftKey || e.metaKey)
                                        ) {
                                            return;
                                        }
                                        // 错误行禁用
                                        let values = record.values || record;
                                        if (
                                            !values ||
                                            !values.saga_status ||
                                            values.saga_status.value !== "1"
                                        ) {
                                            setTableProps("currentIndex", getTrueRowIndex({ rowIndex: index }, 'normal', 'filter'), false);
                                            setTableProps(
                                                "currentInfo",
                                                { record, index },
                                                false,
                                            );
                                        }
                                        isFunction(onRowClick) &&
                                            onRowClick(
                                                this.currentMainCode,
                                                record,
                                                index,
                                                e,
                                                this.currentStore,
                                            );
                                    }}
                                    onRowDoubleClick={(record, index, e) => {
                                        // 错误行禁用
                                        let values = record.values || record;
                                        if (
                                            !values ||
                                            !values.saga_status ||
                                            values.saga_status.value !== "1"
                                        ) {
                                            setTableProps(
                                                "currentIndex",
                                                getTrueRowIndex({ rowIndex: index }),
                                                false,
                                            );
                                            setTableProps(
                                                "currentInfo",
                                                { record, index },
                                                false,
                                            );
                                        }
                                        isFunction(onRowDoubleClick) &&
                                            onRowDoubleClick(record, index, e, this.currentStore);
                                    }}
                                    rowClassName={(record, current) => {
                                        let newRowClassName = rowClassName;
                                        if (isFunction(newRowClassName) || isArray(newRowClassName)) {
                                            current = this.currentStore.getTrueRowIndex({ rowIndex: current }, 'normal', 'filter');
                                        }

                                        let currentCls = [];
                                        if (isArray(newRowClassName)) {
                                            currentCls = newRowClassName.filter(item => {
                                                if (!isArray(item.index)) {
                                                    item.index = [item.index];
                                                }
                                                return item.index.includes(current);
                                            });
                                            newRowClassName = currentCls.reduce(
                                                (total, item) => {
                                                    return total + " " + item.className;
                                                },
                                                "",
                                            );
                                        }

                                        // console.log(currentCls);
                                        return isFunction(newRowClassName)
                                            ? newRowClassName(record, current)
                                            : newRowClassName;
                                    }}
                                    cellClassName={({ record, rowIndex, attrcode }) => {
                                        return (
                                            isFunction(cellClassName) &&
                                            cellClassName(record, rowIndex, attrcode, this.currentStore)
                                        );
                                    }}
                                    checkColConfig={{
                                        showCheck: isUndefined(hideBrowseCheckbox)
                                            ? this.currentConfig.showCheck
                                            : status === "browse"
                                                ? !hideBrowseCheckbox[activeCode]
                                                : this.currentConfig.showCheck,
                                        langJson: this.props.langJson,
                                    }}
                                    indexColConfig={{ showIndex: this.currentConfig.showIndex, langJson: this.props.langJson }}
                                    totalConfig={{
                                        showTotal: this.currentConfig.showTotal,
                                        noTotalRows: this.currentConfig.noTotalRows,
                                        noTotalCol: this.currentConfig.noTotalCol,
                                        allowTotalRows: this.currentConfig
                                            .allowTotalRows,
                                        numberPd: 7,
                                    }}
                                    onCellMouseDown={(record, rowIndex, attrcode, column) => {
                                        let booleanType = ['switch', 'checkbox_switch']
                                        if (status === 'edit' && booleanType.includes(column.itemtype)) {
                                            let rowKey = this.currentStore.getTableProps('rowKey');
                                            let rowKeyValue = record[rowKey];
                                            let params = { column, rowIndex, attrcode, rowKeyValue }
                                            setActiveCell(params)
                                        }
                                    }}
                                    excelConfig={{
                                        tableId: this.currentMainCode,
                                        onBatchChange: this.currentConfig.onBatchChange,
                                        langJson: this.props.langJson,
                                    }}
                                    high_selectable={{
                                        store: this.currentStore,
                                        onSelect: (
                                            activedCells = {},
                                            activeSpecialCells = [],
                                            hotkey,
                                            { current, isBatch },
                                        ) => {
                                            // console.log(activedCells, hotkey, current);
                                            let pervActivedCells = this.currentStore.getCache('activedCells') || {};

                                            for (let rowKeyValue in activedCells) {
                                                pervActivedCells[rowKeyValue] = pervActivedCells[rowKeyValue] || [];
                                                for (let attrcode of activedCells[
                                                    rowKeyValue
                                                ]) {
                                                    pervActivedCells[rowKeyValue].push(attrcode);
                                                    if (isBatchSelect) {
                                                        this.currentStore.setCellProps(
                                                            rowKeyValue,
                                                            attrcode,
                                                            {
                                                                actived: true,
                                                                leftLine: false,
                                                                rightLine: false,
                                                                topLine: false,
                                                                bottomLine: false,
                                                            },
                                                            false,
                                                        );

                                                        let cellObj = this.currentStore.getCache(['cell', rowKeyValue + attrcode]);
                                                        cellObj && cellObj.setState({});
                                                    }
                                                }
                                            }

                                            // 重新赋值
                                            activedCells = pervActivedCells;

                                            if (isBatch) {
                                                let rows = [];
                                                for (let rowKeyValue in activedCells) {
                                                    // TODO
                                                    rows.push(
                                                        getStore("data").query(rowKeyValue),
                                                    );
                                                }
                                                if (onBatchSelected) {
                                                    this.onBatchSelected(rows, hotkey)
                                                } else {
                                                    setSelectedIndex([], 'clear', false);
                                                    rows.forEach(row => {
                                                        let rowKeyValue = row[rowKey];
                                                        let values = row.values || row,
                                                            isSagaStatus = values && values.saga_status && values.saga_status.value === '1';
                                                        // 错误行禁用
                                                        if (isSagaStatus && row.disabled) {
                                                            return;
                                                        }

                                                        let isSelected = hasSelected(rowKeyValue);

                                                        !isSelected && setSelectedIndex([{ rowKey, rowKeyValue, pattern: 'add' }], 'add', false);
                                                    });
                                                }
                                            }

                                            if (hotkey === "down" && current && current.rowKeyValue && status === "edit") {
                                                this.currentStore.setCellProps(
                                                    current.rowKeyValue,
                                                    current.attrcode,
                                                    { showDrag: true },
                                                    false,
                                                );
                                                let cellObj = this.currentStore.getCache(['cell', current.rowKeyValue + current.attrcode]);
                                                cellObj && cellObj.setState({});
                                            }


                                            // 缓存已选
                                            this.currentStore.setCache(
                                                "activedCells",
                                                activedCells,
                                            );
                                            this.currentStore.setCache(
                                                "activedCell",
                                                current,
                                            );

                                            if (!isBatchSelect) {
                                                this.currentStore.refresh();
                                                return;
                                            }
                                            // 选中表头
                                            let oldActiveSpecialCells = this.currentStore.getCache('activeSpecialCells') || [];
                                            if (activeSpecialCells.length > 0 || (activeSpecialCells.length === 0 && oldActiveSpecialCells.length !== 0)) {
                                                oldActiveSpecialCells.forEach(item => {
                                                    // 不能用actived 会有冲突
                                                    this.currentStore.setColumn(activeCode, { [item.attrcode]: { unitActive: false } }, false);
                                                });
                                                activeSpecialCells.forEach(item => {
                                                    // 不能用actived 会有冲突
                                                    this.currentStore.setColumn(activeCode, { [item.attrcode]: { unitActive: true } }, false);
                                                });
                                                this.currentStore.setCache('activeSpecialCells', activeSpecialCells);
                                            }

                                            // 修正边框
                                            if (activedCells || activeSpecialCells) {
                                                let arrayMeta = this.currentStore.getArrayMeta(activeCode, true),
                                                    arrayMetaLen = arrayMeta.length;
                                                let viewData = this.currentStore.getViewData('filter');
                                                let linkObjMeta = arrayMeta.reduce((result, item, i, arr) => {
                                                    let next = arr[i + 1] || {};
                                                    let prev = arr[i - 1] || {};
                                                    if (item.visible) {
                                                        result[item.attrcode] = {
                                                            prev: prev.attrcode,
                                                            current: item.attrcode,
                                                            next: next.attrcode,
                                                        }
                                                    }
                                                    return result;
                                                }, {});

                                                viewData.forEach((row, i) => {
                                                    let values = row.values;
                                                    let prevRowValues = (viewData[i - 1] || {}).values || {};
                                                    let nextRowValues = (viewData[i + 1] || {}).values || {};
                                                    let rowKeyValue = row[rowKey];


                                                    for (let attrcode in values) {
                                                        let cell = values[attrcode];
                                                        if (cell['actived']) {
                                                            let prevCol = linkObjMeta[attrcode] && linkObjMeta[attrcode].prev;
                                                            let nextCol = linkObjMeta[attrcode] && linkObjMeta[attrcode].next;

                                                            cell['leftLine'] = !prevCol || !values[prevCol] || !values[prevCol]['actived'];

                                                            cell['rightLine'] = !nextCol || !values[nextCol] || !values[nextCol]['actived'];

                                                            cell['topLine'] = prevRowValues && (!prevRowValues[attrcode] || !prevRowValues[attrcode]['actived']);

                                                            cell['bottomLine'] = nextRowValues && (!nextRowValues[attrcode] || !nextRowValues[attrcode]['actived']);
                                                            // 单体更新
                                                            let cellObj = this.currentStore.getCache(['cell', rowKeyValue + attrcode]);
                                                            cellObj && cellObj.setState({});
                                                        }
                                                    }
                                                });

                                                // console.log(linkObjMeta, viewData);
                                            }
                                            /// 更新表格
                                            if (hotkey !== 'down' && hotkey !== 'drag') {
                                                this.currentStore.refresh();
                                            }
                                        },
                                        getPrevSelector: key => {
                                            const activedCells = this.currentStore.getCache('activedCells') || {},
                                                prevSelector = {};
                                            for (let rowKeyValue in activedCells) {
                                                (activedCells[rowKeyValue] || []).forEach(attrcode => {
                                                    prevSelector[`${rowKeyValue}@${attrcode}`] = true;
                                                });
                                            }
                                            // console.log(activedCells, prevSelector, 'prevSelector');
                                            return prevSelector;
                                        },
                                        onDeSelect: (deActiveCells, hotkey) => {
                                            let prevActiveCells = this.currentStore.getCache('activedCells') || {};
                                            let prevActiveCellsObj = {};
                                            let prevActiveCellsArr = {};
                                            // 转对象
                                            for (let rowKeyValue in prevActiveCells) {
                                                prevActiveCellsObj[rowKeyValue] = {};
                                                for (let attrcode of prevActiveCells[rowKeyValue]) {
                                                    prevActiveCellsObj[rowKeyValue][attrcode] = true;
                                                }
                                            }

                                            // 清理数据
                                            for (let rowKeyValue in deActiveCells) {
                                                for (let attrcode of deActiveCells[rowKeyValue]) {
                                                    if (prevActiveCellsObj[rowKeyValue][attrcode]) {
                                                        delete prevActiveCellsObj[rowKeyValue][attrcode];
                                                        // setCellProps(rowKeyValue, attrcode, { showDrag: false }, false);
                                                        this.currentStore.setCellProps(rowKeyValue, attrcode, {
                                                            actived: false,
                                                            leftLine: false,
                                                            rightLine: false,
                                                            topLine: false,
                                                            bottomLine: false,
                                                        }, false);
                                                        // 单体更新
                                                        let cellObj = this.currentStore.getCache(['cell', rowKeyValue + attrcode]);
                                                        cellObj && cellObj.setState({});
                                                    }
                                                }
                                            }

                                            // 转数组
                                            for (let rowKeyValue in prevActiveCellsObj) {
                                                prevActiveCellsArr[rowKeyValue] = [];
                                                for (let attrcode in prevActiveCellsObj[rowKeyValue]) {
                                                    prevActiveCellsArr[rowKeyValue].push(attrcode);
                                                }
                                            }

                                            // 重新赋值
                                            this.currentStore.setCache('activedCells', prevActiveCellsArr);
                                        },
                                        onClearData: (shouldForceUpdate = false, hotkey) => {
                                            this.currentStore.setCache('activedCells', {});
                                            this.currentStore.setCache('activeSpecialCells', []);
                                            if (!isBatchSelect) {
                                                return;
                                            }
                                            //let activedCells = this.currentStore.getCache('activedCells') || {};
                                            //let activedCell = this.currentStore.getCache('activedCell');
                                            let data =
                                                this.currentStore.getData() || [];
                                            let rowKey = this.currentStore.getTableProps(
                                                "rowKey",
                                            );
                                            data.forEach(row => {
                                                let values = row.values || row || {};
                                                let rowKeyValue = row[rowKey];
                                                let actived = false;
                                                let showDrag = false;
                                                for (let attrcode in values) {
                                                    showDrag = values[attrcode].showDrag
                                                    showDrag && this.currentStore.setCellProps(
                                                        rowKeyValue,
                                                        attrcode,
                                                        { showDrag: false },
                                                        false,
                                                    );
                                                    actived = values[attrcode].actived
                                                    actived && this.currentStore.setCellProps(
                                                        rowKeyValue,
                                                        attrcode,
                                                        {
                                                            actived: false,
                                                            leftLine: false,
                                                            rightLine: false,
                                                            topLine: false,
                                                            bottomLine: false,
                                                        },
                                                        false,
                                                    );

                                                    if (actived || showDrag) {
                                                        // 单体更新
                                                        let cellObj = this.currentStore.getCache(['cell', rowKeyValue + attrcode]);
                                                        cellObj && cellObj.setState({});
                                                    }
                                                }
                                            });
                                            const columns = this.currentStore.getArrayMeta(activeCode, false) || [];
                                            shouldForceUpdate = false;
                                            columns.forEach(item => {
                                                shouldForceUpdate = shouldForceUpdate || item.unitActive;
                                                item.unitActive && this.currentStore.setColumn(activeCode, { [item.attrcode]: { unitActive: false } }, false);
                                            });
                                            // 暂时先这样判断, 表头被选中时再全局更新
                                            if (shouldForceUpdate) {
                                                this.currentStore.refresh();
                                            }
                                        },
                                        arrColumns: this.currentStore.getArrayMeta(activeCode, false),
                                        data: this.currentData,
                                        rowKey,
                                        onBatchChange: (
                                            activeCell = {},
                                            activeCells = {},
                                        ) => {
                                            if (!isBatchSelect) {
                                                return;
                                            }
                                            // activeCell = {}, activeCells = {}
                                            // 开始  批改以下所有
                                            activeCell = {
                                                ...activeCell,
                                                // , 'normal', 'filter' 要不要转 跟rows有关系
                                                rowIndex: this.currentStore.getTrueRowIndex({
                                                    rowKeyValue: activeCell.rowKeyValue
                                                }, 'normal', 'normal'),
                                                attrcode: activeCell.attrcode,
                                                column: this.currentStore.getColumn(
                                                    activeCode,
                                                    activeCell.attrcode,
                                                ),
                                            };

                                            // 表格数据  是否包含删除的数据 这里要斟酌下
                                            // let rows = store.getViewData('filter');
                                            let rows = this.currentStore.getData();
                                            let viewData = this.currentStore.getViewData();

                                            let pasteContent = [];
                                            let activeCellValue =
                                                getCellValue({
                                                    rowKeyValue: activeCell.rowKeyValue,
                                                    attrcode: activeCell.attrcode,
                                                }) || {};
                                            for (let rowKeyValue in activeCells) {
                                                let attrcode =
                                                    activeCells[rowKeyValue][0];
                                                let isRefer =
                                                    activeCell.column.itemtype ===
                                                    "refer";
                                                attrcode &&
                                                    pasteContent.push(
                                                        isRefer
                                                            ? activeCellValue.display
                                                            : activeCellValue.value,
                                                    );
                                            }

                                            // console.log('onBatchChange trigger', activeCell, activeCells);

                                            let param = {
                                                pasteContent,
                                                activeCell,
                                                langJson,
                                                rows,
                                                viewData,
                                                getRowOldValue: this.currentStore
                                                    .getRowOldValue,
                                                tableId: this.currentMainCode,
                                                store: this.currentStore,
                                            };
                                            let data = excelPackageData(param);
                                            onBatchChange && onBatchChange(data);
                                        },
                                        isBatchSelect,
                                        ...high_selectable,
                                    }}
                                    high_filter={{
                                        alloweFilter: true,
                                        langJson,
                                        panelTitle: this.currentStore.getStore(['meta', this.currentMainCode, 'name']) || '',
                                        filterData: this.currentStore.getViewData('filter'),
                                        customColData: this.currentStore.getCache('customColData'),
                                        rowKey,
                                        isReset: this.currentStore.getStore('filterReset'),
                                        arrColumns,
                                        handleFilterCallBack: (params = {}) => {
                                            let {
                                                filteredRow,
                                                filteredRowKeyValues,
                                                columnStatus = {},
                                                // 原始过滤条件
                                                filterItemList,
                                                // 当前过滤条件
                                                afterFilterItemList,
                                                afterFilterFactors,
                                            } = params;

                                            // console.log(params);

                                            // 更新重置状态
                                            this.currentStore.setStore('filterReset', false, false);

                                            // 设置列状态  设置表格筛选图标
                                            this.currentStore.setColumn(this.currentMainCode, columnStatus, false);

                                            // 设置行状态
                                            let data = this.currentStore.getData() || [];
                                            data.forEach(row => {
                                                if (filteredRowKeyValues.includes(row[rowKey])) {
                                                    row.filter = false;
                                                } else {
                                                    row.filter = true;
                                                }
                                            });

                                            // 处理已选等逻辑
                                            let checkInfo = this.currentStore.getCheckInfo();
                                            if (filteredRow && filteredRow.length) {
                                                checkInfo.indeterminate = filteredRow.some(item => {
                                                    let values = item.values || item;
                                                    let unSaga = !values['saga_status'] || values['saga_status'].value !== '1';
                                                    return unSaga && item.selected;
                                                });
                                                checkInfo.checkedAll = filteredRow.every(item => {
                                                    let values = item.values || item;
                                                    let unSaga = !values['saga_status'] || values['saga_status'].value !== '1';
                                                    return !unSaga || item.selected;
                                                });
                                            }
                                            this.currentStore.setStore(["checkInfo"], checkInfo, false);

                                            // 更新 所有的更新放到弹窗关闭(onHide)时
                                            this.currentStore.setData({ data, shouldForceUpdate: false });
                                            handleFilterCallBack && handleFilterCallBack();
                                        },
                                        handleResetCallBack: params => {
                                            let { filterItemList, columnStatus } = params;
                                            // 更新重置状态
                                            this.currentStore.setStore('filterReset', false, false);
                                            // 设置行状态
                                            let data = this.currentStore.getData() || [];
                                            data.forEach(row => {
                                                row.filter = false;
                                                delete row.filterIndex;
                                            });

                                            // 处理已选等逻辑
                                            let checkInfo = this.currentStore.getCheckInfo();
                                            let viewData = this.currentStore.getViewData();
                                            if (viewData && viewData.length) {
                                                checkInfo.indeterminate = viewData.some(item => {
                                                    let values = item.values || item;
                                                    let unSaga = !values['saga_status'] || values['saga_status'].value !== '1';
                                                    return unSaga && item.selected;
                                                });
                                                checkInfo.checkedAll = viewData.every(item => {
                                                    let values = item.values || item;
                                                    let unSaga = !values['saga_status'] || values['saga_status'].value !== '1';
                                                    return !unSaga || item.selected;
                                                });
                                            }
                                            this.currentStore.setStore(["checkInfo"], checkInfo, false);

                                            // 更新 所有的更新放到弹窗关闭(onHide)时
                                            this.currentStore.setData({ data, shouldForceUpdate: false });

                                            // 列状态
                                            this.currentStore.setColumn(this.currentMainCode, columnStatus, false);
                                            handleResetCallBack && handleResetCallBack();
                                        },
                                        onHide: params => {
                                            let { filterItemList, columnStatus } = params;
                                            this.currentStore.setColumn(this.currentMainCode, columnStatus, true);
                                            // console.log(params);
                                        },
                                        ...high_filter,
                                    }}
                                    contextMenuConfig={{
                                        enable: high_selectable && high_selectable.enable && isBatchSelect,
                                        status: status,
                                        contextMenuClick: this.contextMenuClick,
                                        showBusinessBtn: isFunction(rightMenuClick),
                                        showPasteBtn: isFunction(onBatchChange),
                                        langJson: this.props.langJson,
                                    }}
                                    high_hotkey={{
                                        tableId: this.currentMainCode,
                                        enable: true,
                                        store: this.currentStore,
                                    }}
                                    // 点击单元格，单元格的浏览态编辑态切换时的回调
                                    onCellStatusChange={params => {
                                        let { isEdit, column } = params
                                        if (isEdit) {
                                            setActiveCell(params)
                                        } else {
                                            let cell = getActiveCell()
                                            if (cell) {
                                                let { oldColumn = {} } = cell
                                                if (column.attrcode === oldColumn.attrcode) {
                                                    setActiveCell(null)
                                                }
                                            }
                                        }
                                    }}
                                    //自适应高度
                                    adaptionHeight={adaption}
                                    cardType="card"
                                    otherAreaHeight={maxVisible ? 0 : extraAreaHeight}
                                    //孙表不加悬浮滚动条
                                    addAffixScroll={!isGrandson}
                                    //折行回调
                                    foldingRowChange={isEllipsis => {
                                        this.setState({ isEllipsis })
                                    }}
                                    foldCacheId={foldCacheId}
                                    bodyDisplayInRow={isUndefined(multipleRowCell) ? this.state.isEllipsis : !multipleRowCell}
                                    bodyExpandAllInRow={bodyExpandAllInRow}
                                    //滚动条悬浮
                                    autoAffixScroll
                                    //——————————操作列————————————
                                    colsSettingParam={colSettingParam}
                                    cancelCustomRightMenu={cancelCustomRightMenu}
                                    showWidthAndHeightConfig={showWidthAndHeightConfig}
                                    updateColumnHandle={this.updateColumnAfterSetting}
                                    key={this.currentStore.getStore('key')}
                                    //——————————操作列end————————————
                                    //空白列
                                    addBlankCol={addBlankCol}
                                    {...othersConfig}
                                />
                            )}
                            {/*全展开*/}
                            {status === "browse" && tableVisible && ListViewVisible ? (
                                <AllRowsExpand
                                    tableDom={this.table}
                                    maxVisible={maxVisible}
                                    adaptionHeight={adaption}
                                    otherAreaHeight={extraAreaHeight}
                                    mainCode={this.currentMainCode}
                                    store={this.currentStore}
                                    meta={getStore("meta")}
                                    data={this.currentData}
                                    config={this.currentConfig}
                                    langJson={langJson}
                                    setCellValue={setCellValue}
                                    getCellValue={getCellValue}
                                />
                            ) : null}
                        </div>
                }

            </div>
        );
    };

    render() {
        let { maxVisible } = this.mainStore.getStore("tableSwitch");
        let { isGrandson } = this.props;
        if (maxVisible) {
            return <MaxBox className="card-table-max-box" zIndex={isGrandson ? 240 : 210} content={this.createContent()} />;
        } else {
            return this.createContent();
        }
    }
}
