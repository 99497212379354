/*
 * @Descripttion: 
 * @version: 
 * @Author: bbq
 * @Date: 2020-07-22 17:59:16
 * @LastEditors: bbq
 * @LastEditTime: 2021-01-11 19:39:13
 */
import React, { Component } from 'react';
import CardTable from '../SingleCardTable';
import getCardTableStore from '../SingleCardTable/store';
import { utils } from '@platform/table-core';
const { isFunction, isArray } = utils;

export default class MultiCardTable extends Component {
	constructor(props) {
		super(props);
		//模板
		this.multiCodeArr = null;
		// 存一下id 这个导致 一个store 只能 绑定一个对象
		// 但是没有id 难解决 多来源 主表和多来源本身的共用一个对象的问题
		props.store && props.store.setStore('name', props.name, false);
		//孙表配置
		this.tabsStore = { [this.props.name]: this.props.store };
		// 如果要实现 可以通过meta控制 布局 那么 最外层的store 应该绑定在Wrapper层
		// if (!props.store.getRef().length) {
		this.props.store.setRef && this.props.store.setRef(this);
		// }
	}

	getMultiCodeArr = () => {
		let { store, name } = this.props;

		let meta = store.getStore('meta');
		//孙表横向多页签的主表区域编码
		let containerrelation = meta.containerrelation;
		if (containerrelation && Array.isArray(containerrelation[name])) {
			this.multiCodeArr = containerrelation[name];

			this.setTabsStore(meta);
		}
	};

	// getMainCode= (meta, activeCode)=>{
	//     let { metaTabs } = this.props;
	//     let mainCode = activeCode;
	//     for(let code of metaTabs){
	//         if(meta.gridrelation && meta.gridrelation[code]){
	//             let relation =  meta.gridrelation[code].tabRelation;
	//             if(isArray(relation) && relation.includes(activeCode)){
	//                 mainCode = code;
	//             }
	//         }
	//     }
	//     return mainCode;
	// }

	setTabsStore = (meta) => {
		let { name, store, mountChildrenStore } = this.props;
		let tableStores = store.getStore('tableStores');
		//为多页签其它主区域创建Store, 并挂到Page中
		Array.isArray(this.multiCodeArr) &&
			this.multiCodeArr.forEach((item) => {
				item.forEach((ele) => {
					if (ele !== name) {
						let currentMainCode = ele;
						// if (meta[ele]) {
						//     currentMainCode = this.getMainCode(meta, ele);
						// }

						if (isFunction(mountChildrenStore)) {
							//待优化:---this绑定到cardTable
							// this.tabsStore[currentMainCode].setRef(this);
							// 外部先去取 然后再挂载  把pageScope 上的合并过来
							mountChildrenStore(currentMainCode, (mountedStore) => {
								// 是否挂载了 Store
								this.tabsStore[currentMainCode] =
									mountedStore || this.tabsStore[currentMainCode] || getCardTableStore();
								this.tabsStore[currentMainCode].setStore('meta', meta, false);
								return this.tabsStore[currentMainCode];
							});
						} else {
							this.tabsStore[currentMainCode] = this.tabsStore[currentMainCode] || getCardTableStore();
							this.tabsStore[currentMainCode].setStore('meta', meta, false);
						}

						// 存储一下 所有cardTable的数据源
						store.setStore('tableStores', this.tabsStore, false);
					}
				});
			});
	};

	render() {
		this.getMultiCodeArr();
		let { renderItems, onCellHyperlinkTo, getHotKeyConfig, cardTableComponentUpdate } = this.props;
		return (
			<div className="multi-cardTable">
				{Array.isArray(this.multiCodeArr) &&
					this.multiCodeArr.map((item, index) => {
						let mainCode = item[0];
						return (
							<CardTable
								key={index}
								onCellHyperlinkTo={onCellHyperlinkTo}
								//单来源页签属性
								name={mainCode}
								langJson={this.props.langJson}
								renderItems={renderItems}
								getHotKeyConfig={getHotKeyConfig}
								cardTableComponentUpdate={cardTableComponentUpdate}
								//多页签store及多页签config
								tabsStore={this.tabsStore}
								//全部水平排列的横向多页签
								isHorizontal={this.multiCodeArr.length === 1}
								tabsConfig={this.props.multiConfig}
								metaTabs={item}
							/>
						);
					})}
			</div>
		);
	}
}
