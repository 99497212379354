/*
 * @Descripttion: 
 * @version: 
 * @Author: bbq
 * @Date: 2020-09-05 14:33:22
 * @LastEditors: bbq
 * @LastEditTime: 2021-02-21 14:57:42
 */
//import EditTableStore from "../../../EditTableStore";

import { EditTableStore } from '@platform/table-core';
import { isArray } from '../../../../tableLibs/utils';
import { hookFactory } from '@platform/template';

export class TabsStore extends EditTableStore {
    constructor(props) {
        super(props);

        // 合并一下属性  不然会导致  get 方法取不到值 by bbqin
        this.store = {
            ...this.store,
            //最大化
            tableSwitch: { maxVisible: false },
            activeCell: null,
            //控制页签显隐性 
            hideTabsCode: {},
        };
    }

    /*** 最大化控制 */
    openMaxView(isShow) {
        this.setStore(['tableSwitch', 'maxVisible'], isShow)
    }

    isMaxView() {
        return this.getStore(['tableSwitch', 'maxVisible']);
    }

    /** 隐藏表格 */
    toggleTableShow = flag => {
        this.setStore(['tableSwitch', 'tableVisible'], flag);
    }
    // liuming
    getActiveCell() {
        return this.getStore(['activeCell']);
    }
    // liuming
    setActiveCell(param, shouldForceUpdate = false) {
        this.setStore(['activeCell'], param, shouldForceUpdate);

    }

    // 清理validated
    clearValidated(shouldForceUpdate = true) {
        let validateFailed = this.getCache(['validateFailed']);
        if (validateFailed) {
            for (let tableId in validateFailed) {
                if (validateFailed[tableId]) {
                    for (let tabId in validateFailed[tableId]) {
                        if (validateFailed[tableId][tabId]) {
                            for (let rowKeyValue in validateFailed[tableId][tabId]) {
                                if (validateFailed[tableId][tabId][rowKeyValue]) {
                                    // 先判断一下行
                                    let data = this.getStore('data');
                                    let row = data && data.query(rowKeyValue);
                                    if (!row) {
                                        delete validateFailed[tableId][tabId][rowKeyValue];
                                    } else {
                                        for (let attrcode in validateFailed[tableId][tabId][rowKeyValue]) {
                                            if (attrcode) {
                                                let cellProps = this.getCellProps({ rowKeyValue, attrcode });
                                                cellProps.validated !== false && (delete validateFailed[tableId][tabId][rowKeyValue][attrcode])
                                            }
                                        }
                                        if (Object.keys(validateFailed[tableId][tabId][rowKeyValue]).length === 0) {
                                            delete validateFailed[tableId][tabId][rowKeyValue];
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        shouldForceUpdate && this.refresh();
    }

    getHooks() {
        return {
            ...this.getTableHooks(),
            openMaxView: this.openMaxView.bind(this),
            isMaxView: this.isMaxView.bind(this),
            toggleTableShow: this.toggleTableShow.bind(this),
            getActiveCell: this.getActiveCell.bind(this),
            setActiveCell: this.setActiveCell.bind(this),
            clearValidated: this.clearValidated.bind(this),
        };
    };
}

export default hookFactory(TabsStore)
