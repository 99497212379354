/*
 * @Descripttion: CardTable表格
 * @version:
 * @Author: bbq
 * @Date: 2020-07-21 10:13:18
 * @LastEditors: bbq
 * @LastEditTime: 2021-01-11 19:38:56
 */
import React, { Component } from "react";
import SingleRowExpand from '../SingleRowExpand';
import TabsTable from '../TabsTable';
import TableSideBox from '../SideBox';
import { utils } from '@platform/table-core';
const { isArray, isFunction, isObject, isString, isEmptyValue } = utils;

class CardTable extends Component {
    constructor(props) {
        super(props);
        this.mainSource = this.props.tabsStore[this.props.name];
        // 当前激活页签状态存在主区域store
        let oldSwitch = this.mainSource.getStore('tableSwitch');
        this.initSwitch = {
            ...oldSwitch,
            activeCode: oldSwitch.activeCode ? oldSwitch.activeCode : this.props.name,
        }
        this.mainSource.setStore('tableSwitch', this.initSwitch, false);

        // 给每一个数据源设置一个 name  用于做initialvalue的解析
        this.mainSource.setStore('name', this.props.name, false);

        //子表使用的是 singleCardTable
        for (let source of this.props.metaTabs) {
            // if (!props.tabsStore[source].getRef().length) {
            props.tabsStore[source].setRef(this);
            // }
        }

    }

    componentDidMount() {
        //this.props.store._initCellPropsByMeta(this.props.name, this.props.meta);
        let { name, tabsConfig } = this.props;
        if (tabsConfig[name]) {
            let { pkcode } = tabsConfig[name];
            this.mainSource.setStore('cardTable_pkcode', pkcode, false);
        }
    }

    // componentWillUnmount(){
    //     for (let source of this.props.metaTabs) {
    //         props.tabsStore[source].removeRef(this);
    //     }
    // }

    getMainCode = (meta, activeCode) => {
        let { metaTabs } = this.props;
        let mainCode = activeCode;
        for (let code of metaTabs) {
            if (meta.gridrelation && meta.gridrelation[code]) {
                let relation = meta.gridrelation[code].tabRelation;
                if (isArray(relation) && relation.includes(activeCode)) {
                    mainCode = code;
                }
            }
        }
        return mainCode;
    }

    getCurrentProps = () => {
        // debugger;
        let { name, tabsStore, tabsConfig } = this.props;
        let { activeCode } = this.mainSource.getStore('tableSwitch');

        let currentMainCode = activeCode;
        let meta = tabsStore[name].getStore('meta');
        if (meta[activeCode]) {
            currentMainCode = this.getMainCode(meta, activeCode);
        }

        //当前激活页签主区域store和config
        return {
            currentSource: tabsStore[currentMainCode],
            currentConfig: tabsConfig[currentMainCode] || {},
            currentMainCode: currentMainCode,
        }
    }

    // 给表单用
    onBeforeEvent = async params => {
        //当前激活页签主区域store和config
        let { currentSource, currentConfig, currentMainCode } = this.getCurrentProps();
        let { onBeforeEvent } = currentConfig;
        let {
            getTrueRowIndex,
            setTableProps,
        } = currentSource;

        let {
            record,
            rowKey,
            rowKeyValue,
            rowIndex,
            attrcode,
            column,
            value = {},
            validated,
            event,
            triggerType, // onClick || onFocus
        } = params;

        // 用于解决点击前赋值不上的问题
        setTableProps("currentIndex", getTrueRowIndex({ rowIndex: rowIndex }, 'normal', 'filter'), false);
        setTableProps(
            "currentInfo",
            { record, index: rowIndex },
            false,
        );

        // 处理校验状态
        if (validated === false) {
            let name = currentSource.getStore("name");
            let activeCode = this.mainSource.getStore(['tableSwitch', 'activeCode']);
            let validateFailed = this.mainSource.getCache(['validateFailed', name, activeCode]);

            if (validateFailed && validateFailed[rowKeyValue]) {
                let oldLen = Object.keys(validateFailed).length;
                delete validateFailed[rowKeyValue][attrcode];

                if (oldLen > 0 && Object.keys(validateFailed[rowKeyValue]).length === 0) {
                    delete validateFailed[rowKeyValue];
                    this.mainSource.refresh();
                }
            }
        }

        // 检验输入字符长度
        if (
            triggerType === "onFocus" &&
            column.maxlength
        ) {
            event.target.isFlag = false;
            event.target.addEventListener(
                "compositionstart",
                e => {
                    e.target.isFlag = true;
                },
                false,
            );
            event.target.addEventListener(
                "compositionend",
                e => {
                    e.target.isFlag = false;
                    let flag = e.target.isFlag;
                    let val = e.target.defaultValue;
                    // if (!flag && val.length > item.maxlength) {
                    //     // val = val.substring(0, count);
                    //     val = val.substring(0, item.maxlength);
                    //     rows[index].values[item.attrcode].value = val;
                    //     toast({
                    //         color: 'danger',
                    //         title: `${json && json['table_tips']}`,
                    //         // content: `${json && json['table_tips_content']}${item.maxlength / 2}${json && json['table_maxlength_unit']}`
                    //         content: `${json && json['table_tips_content']}${item.maxlength}${json && json['table_maxlength_unit']}`
                    //     });
                    //     e.target && e.target.blur();
                    // }
                },
                false,
            );
            // TODO this.setState({ table: this.state.table });
        }
        // 多语控件都不走这里
        if (column.itemtype !== "residtxt") {
            // onFocus 和value  用于批量更改   zhanghengh 18/6/29 先注释，以后可能有用
            currentSource.setCache("oldBatchData", {
                // TODO  转换为过滤后的index
                // 在onchange时缓存列数据的key 和value  用于批量更改   zhanghengh 18/6/29
                batchChangeIndex: rowIndex,
                batchChangeKey: column.attrcode,
                batchChangeValue: value.value,
                batchChangeDisplay: value.display,
                // batchChangeValue: isMulti ? theValue : theValue.value,
                // batchChangeDisplay: isMulti ? null : theValue.display
            });
        } else {
            // 这里批改多语存值可能没用，之后在看一下，先留着
            // 多语批改
            // 这里是多语文本的编辑显示逻辑
            // attrcode字段对应的value是主语言
            // 显示规则是有登陆语言显示登陆语言，登陆语言通过getLangCode获得，没有登陆语言显示主语言，attrcode字段对应的value，主语言语言在没有，就什么也不显示
            // const cellData = record.values[item.attrcode];
            // let value = isObj(cellData) ? (cellData.value ? cellData.value : null) : null;
            // const LangCode = getLangCode();
            // const loginLang = item.languageMeta.filter(i => i.languageCode == LangCode);
            // if (loginLang.length > 0) {
            //     loginLang[0].index === '1' ? (loginLang[0].index = '') : '';
            //     if (
            //         record.values[item.attrcode + loginLang[0].index] &&
            //         record.values[item.attrcode + loginLang[0].index].value
            //     ) {
            //         value = record.values[item.attrcode + loginLang[0].index].value;
            //     }
            // }
            currentSource.setCache("oldBatchData", {
                batchChangeIndex: rowIndex,
                batchChangeKey: column.attrcode,
                batchChangeValue: value.value,
                batchChangeDisplay: null,
            });
        }

        // 有一些操作要放在这里处理 设置行状态等等
        if (isFunction(onBeforeEvent)) {
            return (
                (await onBeforeEvent({
                    ...params,
                    moduleId: currentMainCode,
                    currentStore: currentSource,
                })) !== false
            );
        }
        return true;
    };

    // 给表单用
    onAfterEvent = async params => {
        //当前激活页签主区域store和config
        //当前激活页签主区域store和config
        let { currentSource, currentConfig, currentMainCode } = this.getCurrentProps();

        let {
            autoAddRow = {},
            onAfterEvent,
        } = currentConfig;
        let {
            getTableProps,
            setCellValue,
        } = currentSource;

        let {
            record,
            rowKey,
            rowKeyValue,
            rowIndex,
            attrcode,
            column,
            value = {},
            oldValue = {},
            componentValue,
            event,
            triggerType = "onChange", // onChange || onBlur
            isTriggerModal = false,
        } = params;

        // 多语 获取当前的code
        if (
            column.itemtype === "residtxt" &&
            isObject(componentValue)
        ) {
            for (let key in componentValue) {
                if (componentValue[key].current) {
                    // value.value = componentValue[key];
                    // oldValue.value = store.getRowOldValue(record[rowKey], key);
                }
            }
        }

        // 新旧值
        oldValue.value =
            oldValue.value ||
            currentSource.getRowOldValue(
                record[rowKey],
                attrcode,
            );

        if (triggerType === "onBlur") {
            /*
            * onBlur编辑后 和 切换控件状态
            * 1、input类型    line   编辑后 + 切状态
            *                model  编辑后
            * 2、uninput类型  line   切状态
            *                model  无编辑后  无切状态
            */

            // 这一段可以考虑去除  输入 类型
            if (isString(value.value)) {
                value.value = value.value.trim();
            }

            // 当数值类型只输入一个'-'的时候就将它赋值成0
            if (
                column.itemtype === "number" &&
                value.value === "-"
            ) {
                value.value = 0;
                currentSource.setCellValue({
                    rowKeyValue,
                    attrcode,
                    value,
                });
            }

            // 侧拉里面的状态
            if (
                column.itemtype === "select" &&
                isTriggerModal
            ) {
                currentSource.setCellProps(
                    rowKeyValue,
                    attrcode,
                    { open: false },
                );
            }

            if (column.itemtype === "residtxt") {
                if (isTriggerModal) {
                    // 当侧拉的情况下
                    // 多语  登陆语言值 赋值给主语言
                    // tableModeldata.values[item.attrcode] = {
                    //     ...tableModeldata.values[item.attrcode],
                    //     value: val[item.attrcode].value
                    // };
                } else {
                    // 当表体的情况下
                }
                // 多语  登陆语言值 赋值给主语言
                // 主语言没值时，取登录语言的值
                if (!record.values[attrcode]?.value) {
                    let loginCode = getLangCode(),
                        loginIndex = column.languageMeta.find(e => e.languageCode === loginCode)?.index || '',
                        loginValue = componentValue[attrcode + loginIndex] || componentValue[attrcode];
                    setCellValue({ rowKey, rowKeyValue, rowIndex, attrcode, value: loginValue });
                }
            }

            // 多语控件都不走这里
            if (column.itemtype !== "residtxt") {
                // 在onblur时缓存列数据的key 和value  用于批量更改   zhanghengh 18/6/29
                // pageScope.batchData[moduleId] = {
                //     batchChangeIndex: outputIndex,
                //     batchChangeKey: item.attrcode,
                //     batchChangeValue: record.values[item.attrcode] ? record.values[item.attrcode].value : '',
                //     batchChangeDisplay: record.values[item.attrcode] ? record.values[item.attrcode].display : ''
                // };
            } else {
                // 多语批改
                // 这里是多语文本的编辑显示逻辑
                // attrcode字段对应的value是主语言
                // 显示规则是有登陆语言显示登陆语言，登陆语言通过getLangCode获得，没有登陆语言显示主语言，attrcode字段对应的value，主语言语言在没有，就什么也不显示
                // const cellData = record.values[item.attrcode];
                // let value = isObj(cellData) ? (cellData.value ? cellData.value : null) : null;
                // const LangCode = getLangCode();
                // const loginLang = item.languageMeta.filter(i => i.languageCode == LangCode);
                // if (loginLang.length > 0) {
                //     loginLang[0].index === '1' ? (loginLang[0].index = '') : '';
                //     if (
                //         record.values[item.attrcode + loginLang[0].index] &&
                //         record.values[item.attrcode + loginLang[0].index].value
                //     ) {
                //         value = record.values[item.attrcode + loginLang[0].index].value;
                //     }
                // }
                // pageScope.batchData[moduleId] = {
                //     batchChangeIndex: outputIndex,
                //     batchChangeKey: item.attrcode,
                //     batchChangeValue: value,
                //     batchChangeDisplay: null
                // };
            }
        }

        // 设置状态  放在  onAfterEvent 事件之前 避免onAfterEvent 修改record报错
        currentSource.setCellProps(
            rowKeyValue,
            attrcode,
            { isEdit: false },
        );

        // 有一些操作要放在这里处理 设置行状态等等

        let next =
            isFunction(onAfterEvent) &&
            onAfterEvent(
                {
                    ...params,
                    moduleId: currentMainCode,
                },
                currentSource,
            ) !== false;

        // 多语 获取当前的code
        if (
            column.itemtype === "residtxt" &&
            isObject(componentValue)
        ) {
            for (let key in componentValue) {
                currentSource.saveRowOldValue(
                    record[rowKey],
                    key,
                    componentValue[key].value,
                );
            }
        } else {
            currentSource.saveRowOldValue(
                record[rowKey],
                attrcode,
                value.value,
            );
        }

        if (!next) {
            console.log("not next to run");
            return false;
        }

        /**
         * 自动增行
         * 增一行的条件：
         * 1、最后一行
         * 2、isAddRow 为true
         * 3、当前单元格值不为空
         */

        let viewLen = currentSource.getViewData().length;
        let isSwitch_browseDisabled =
            column.itemtype === "switch_browse" &&
            column.disabled; // 开关且不可编辑
        let validValue = column.itemtype === 'residtxt' ? value : value.value;
        validValue = column.itemtype === 'refer' ? componentValue : value;
        const isEmpty = isEmptyValue(validValue, column.itemtype);
        const tableStatus = getTableProps('status');

        if (
            viewLen == rowIndex + 1 &&
            autoAddRow.enabled &&
            !isSwitch_browseDisabled &&
            !isEmpty &&
            tableStatus === "edit"
        ) {
            // 添加自动增行后的回调
            currentSource.addTableRow(
                undefined,
                autoAddRow.defaultValue,
                true,
                {
                    callback: autoAddRow.callback,
                    isAutoAddRow: true,
                },
            );
        }
    };

    render() {
        let { name, tabsStore, tabsConfig, metaTabs, langJson = {}, renderItems, getHotKeyConfig, cardTableComponentUpdate, onCellHyperlinkTo } = this.props;

        let { currentSource, currentConfig, currentMainCode } = this.getCurrentProps();

        let { getStore, closeSideBox } = currentSource;
        let { record, index, sideBoxVisible, status } = getStore('tableSideBox');
        let meta = getStore('meta');
        let expandedRowKeys = getStore('expandedRowKeys');

        // console.log('tabsConfig', tabsConfig);  // 点击行时会调用 10多次 有些问题TODO

        return (
            <div className={this.props.isHorizontal ? '' : 'nc-bill-table-area'}>
                <TabsTable
                    mainCode={name}
                    isMultiTab={true}
                    tabsStore={tabsStore}
                    tabsConfig={tabsConfig}
                    metaTabs={metaTabs}
                    langJson={langJson}
                    renderItems={renderItems}
                    getHotKeyConfig={getHotKeyConfig}
                    cardTableComponentUpdate={cardTableComponentUpdate}
                    onCellHyperlinkTo={onCellHyperlinkTo}
                    isHorizontal={this.props.isHorizontal}

                    //行展开
                    haveExpandIcon={() => true}
                    expandedRowKeys={expandedRowKeys}
                    expandedRowRender={(record, index) => {
                        return (
                            <SingleRowExpand
                                moduleId={currentMainCode}
                                mainCode={name}
                                tabsStore={tabsStore}
                                record={record}
                                rowIndex={index}
                                meta={meta}
                            />
                        );
                    }}
                />

                {/* 侧拉框 */}
                {
                    sideBoxVisible &&
                    <TableSideBox
                        mainCode={currentMainCode}
                        store={currentSource}
                        config={{ ...currentConfig, onAfterEvent: this.onAfterEvent, onBeforeEvent: this.onBeforeEvent }}
                        renderItems={renderItems}
                        getHotKeyConfig={getHotKeyConfig}
                        cardTableComponentUpdate={cardTableComponentUpdate}
                        // 传递多语
                        langJson={langJson}
                        show={sideBoxVisible}
                        status={status}
                        data={record}
                        index={index}
                        onClose={closeSideBox}
                        onCellHyperlinkTo={onCellHyperlinkTo}
                    />
                }

            </div>
        )
    }
}

export default CardTable;