/*
 * @Descripttion: 分发单来源 与 多来源
 * @version: 
 * @Author: bbq
 * @Date: 2020-07-07 13:53:43
 * @LastEditors: bbq
 * @LastEditTime: 2020-09-05 13:57:05
 */

import React, { Component } from "react";
import useCardTable from './store';
import TabsTableStore from './components/TabsTable/store';
import SingleCardTable from './components/SingleCardTable';
import MultiCardTable from './components/MultiCardTable';
import { getMultiLang } from "@platform/api";

class CardTableWrapper extends Component {
    constructor(props) {
        super(props);
        props.store.setRef && props.store.setRef(this);
        // console.log(props.store, ' props.store');
        // 为了主表和多来源总数据 共用对象时使用
        props.store.setStore('name', props.name, false);
    }

    UNSAFE_componentWillMount() {
        let { store, afterLangload } = this.props;

        //加载多语
        let callback = json => {
            // this.setState({ json });
            store.setCache('langJson', json || {});
            // console.log(store.getCache('langJson'))
            // 备份一份到全局
            afterLangload && afterLangload(json, store);
            this.forceUpdate();
        };
        getMultiLang({ moduleId: "page_table", callback });
    }

    isMulti() {
        let { name, store } = this.props;
        let meta = store.getStore('meta');
        //孙表横向多页签的主表区域编码
        let containerrelation = meta.containerrelation;
        if (
            containerrelation &&
            Array.isArray(containerrelation[name])
        ) {
            return true;
        }
        return false;
    }

    getCardTableProps = isMulti => {
        let { name, store, config, ...other } = this.props;
        if (!isMulti) {
            return {
                tabsStore: { [name]: store },
                tabsConfig: { [name]: config },
                metaTabs: [name],
                name,
                ...other,
            }
        }
    }

    render() {
        let isMulti = this.isMulti();
        let cardTableProps = this.getCardTableProps();
        let store = this.props.store;
        if (!store || !store.getViewMeta()) {
            return <div className="unknow-store"></div>;
        }
        let langJson = store.getCache('langJson') || {};
        return isMulti ? <MultiCardTable {...this.props} langJson={langJson} /> : <SingleCardTable {...cardTableProps} langJson={langJson} isHorizontal={true} />;
    }

}

CardTableWrapper.displayName = 'CardTable';
CardTableWrapper.useCardTable = useCardTable;
CardTableWrapper.TabsTableStore = TabsTableStore;

export default CardTableWrapper;