import React from 'react';
import { HotKeys } from 'react-hotkeys';
import { ViewModel } from '@platform/api';
import { WithKeyboardChange } from '@platform/base';
const { executeAutoFocus, createKeyMap, stopEnterHotKey, calculateNeedJumpTimes } = WithKeyboardChange
const { getFirstItem, setValueOfActiveTable } = createKeyMap;

function findParentTd(el) {
    while (el.parentElement) {
        el = el.parentElement;
        if (el.tagName === 'TD') {
            return el;
        }
    }
    return null;
}

const keyMap = {
    nextTabHandler: ['right', 'down'],
    nextTabUSRHandler: ['shift+right'],
    prevTabHandler: ['left', 'up'],
    prevTabUSLHandler: ['shift+left'],
    expand: ['space'],
    enter: ['enter'],
};

/**
 * @author bbqin
 * @param {*} props
 * @description  tab页签的hotkey逻辑
 * @return {null}
 */
export function TabHotKey(props) {
    let {
        hotKeyboard = true,
        wrapperId,
        headerId,
        activeKey,
        moduleId,
        ...others
    } = props || {};

    /**
     * @description 获取活跃元素
     * @param {*} comboKey
     */
    function getActiveTabElement(comboKey) {
        let wrapper = document.querySelector(`#${wrapperId}`);
        let activeTabTitle = document.querySelector(`#${headerId} li.active`);
        if (!activeTabTitle) {
            return null;
        }
        if (
            !comboKey &&
            activeTabTitle.firstElementChild === document.activeElement
        ) {
            return activeTabTitle;
        }
        if (comboKey && wrapper && wrapper.contains(document.activeElement)) {
            return activeTabTitle;
        }
        return null;
    }
    /**
     * @description 获取将要聚焦的元素
     * @param {*} dir
     * @param {*} comboKey
     */
    function getWillActiveTabElement(dir) {
        let activeTabTitle = document.querySelector(`#${headerId} li.tabs-item.use-tab-hotkey.active`),
            // first = document.querySelector(`#${headerId} li.tabs-item:first-child`),
            // last = document.querySelector(`#${headerId} li.tabs-item:last-child`),
            $li = document.querySelectorAll(`#${headerId} li.tabs-item.use-tab-hotkey`) || [],
            lilen = $li.length,
            first = $li[0],
            last = $li[lilen - 1],
            willActiveElement = activeTabTitle;

        if (dir === 'prev' && activeTabTitle === first) {
            willActiveElement = last;
        } else if (dir === 'prev' && activeTabTitle.previousElementSibling) {
            willActiveElement = activeTabTitle.previousElementSibling;
        }
        if (dir === 'next' && activeTabTitle === last) {
            willActiveElement = first;
        } else if (dir === 'next' && activeTabTitle.nextElementSibling) {
            willActiveElement = activeTabTitle.nextElementSibling;
        }

        return willActiveElement;
    }
    setValueOfActiveTable({ moduleId, activeKey });
    return hotKeyboard ? (
        <HotKeys
            keyMap={keyMap}
            handlers={{
                nextTabHandler: () => {
                    let activeTabTitle = getActiveTabElement(false);
                    // console.log(e, document.activeElement, activeTabTitle);
                    // 如果当前focus元素在页签下
                    if (activeTabTitle) {
                        // console.log("next tab");
                        let next = getWillActiveTabElement('next');
                        let $focus = next.querySelector('[tabindex],a');
                        $focus && $focus.focus();
                        next.click();
                        // e.preventDefault();
                    }
                },
                nextTabUSRHandler: e => {
                    let activeTabTitle = getActiveTabElement(e.shiftKey);
                    // 如果当前focus元素在页签下
                    if (activeTabTitle) {
                        // console.log("next tab");
                        let next = getWillActiveTabElement('next');
                        // next.firstElementChild.className += ' focused';
                        let $focus = next.querySelector('[tabindex],a');
                        $focus && $focus.focus();
                        next.click();
                        // e.preventDefault();
                    }
                },
                prevTabHandler: () => {
                    let activeTabTitle = getActiveTabElement(false);
                    // 如果当前focus元素在页签下
                    if (activeTabTitle) {
                        // console.log('prev tab');
                        let prev = getWillActiveTabElement('prev');
                        let $focus = prev.querySelector('[tabindex],a');
                        $focus && $focus.focus();
                        prev.click();
                        // e.preventDefault();
                    }
                },
                prevTabUSLHandler: e => {
                    let activeTabTitle = getActiveTabElement(e.shiftKey);
                    // 如果当前focus元素在页签下
                    if (activeTabTitle) {
                        // console.log('prev tab');
                        let prev = getWillActiveTabElement('prev');
                        let $focus = prev.querySelector('[tabindex],a');
                        $focus && $focus.focus();
                        prev.click();
                        // e.preventDefault();
                    }
                },
                expand: e => {
                    // let wrapper = document.querySelector(`#${wrapperId}`);
                    let activeTabTitle = document.querySelector(`#${headerId}`);
                    // 如果当前focus元素在页签下
                    if (activeTabTitle.contains(document.activeElement)) {
                        e.preventDefault();
                        console.log('sapce trigger!');
                        // e.preventDefault();
                        activeTabTitle.querySelector('i').click();
                    }
                },
                enter: e => {
                    let target = e.target;
                    if (stopEnterHotKey(e.target)) return;

                    let container = document.getElementById(headerId);
                    let item = getFirstItem(activeKey);
                    if (item && container.contains(target)) {
                        let div = document.querySelector(`div[${item}] [tabindex="0"]`);

                        //判断当前行是否可以编辑
                        let nextFocusTd = div && findParentTd(div);
                        let nextFocusTr = nextFocusTd && nextFocusTd.parentElement;
                        if (nextFocusTr && nextFocusTr.getAttribute('tabindex') === '-1') {
                            let needJumpTimes = 0;
                            needJumpTimes = calculateNeedJumpTimes(nextFocusTr, needJumpTimes, 'down');
                            let needJumpItemCode = item.replace(/\d+$/, needJumpTimes);
                            div = document.querySelector(`div[${needJumpItemCode}] [tabindex="0"]`);
                        }
                        executeAutoFocus({ div, ViewModel });
                        div = null;
                    }
                },
            }}
            className="tabs-hotkeys-wrapper"
            focused={true}
            attach={document.body}
            {...others}
        >
            {props.children}
        </HotKeys>
    ) : null;
}

export default TabHotKey;
