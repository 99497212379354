import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Sidebox, Button } from '@platform/base';
import { getMultiLang, getSysFieldid } from '@platform/api';
import { utils, CONFIG } from '@platform/table-core';
import Form from '@platform/form';
import MultiTabsTable from './MultiTabsTable';

const { isFunction, isArray, isObject, isBoolean } = utils;
const { ROW_STATUS } = CONFIG;
import "./index.less";

export default class TableSideBox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            json: {},
            index: this.props.index,
            buttonDisabled: [],
            clientWidth: 0,
        };
        this.formStore = Form.useForm();

        //当前index
        this.index = this.props.index;
        //当前
        this.record = this.props.data;
        this.grandTableConfig = {};
        if (this.props.config && isArray(this.props.config.grandGroup)) {
            this.props.config.grandGroup.forEach(item => {
                this.grandTableConfig[item.grandTableId] = item.grandTableConfig;
            });
        }

        // 这里的index 应该就是 normal index
        this.props.store.setTableProps('currentIndex', this.props.store.getTrueRowIndex({ rowIndex: this.index }, 'normal', 'filter'), false);
        this.props.store.setStore(['tableSideBox', 'index'], this.index, false);
        this.props.store.setStore(['tableSideBox', 'record'], this.record, false);
    }

    UNSAFE_componentWillMount() {
        //加载多语
        let callback = json => {
            this.setState({ json });
        };
        getMultiLang({ moduleId: 'table_cardTable', callback });

        this.setState({
            clientWidth: document.body.clientWidth
        })
        let tableData = this.props.store.getViewData();
        let length = tableData.length;
        this.setFormMeta();
        this.updateSideBox(true, length);
    }

    componentDidMount() {
        window.addEventListener("resize", this.calcSideBoxWidth);
    }

    calcSideBoxWidth = () => {
        this.setState({
            clientWidth: document.body.clientWidth
        })
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        let { onClose, store, index } = nextProps;
        let tableRows = store.getViewData();
        // 删除到最后一行时避免报错
        if (!tableRows[index]) {
            console.warn('not find data, please check!');
            this.record = {};
            store.setTableProps('currentIndex', -1, false);
            store.setStore(['tableSideBox', 'index'], -1, false);
            store.setStore(['tableSideBox', 'record'], null, false);
            isFunction(onClose) && onClose();
            return;
        } else {
            this.index = index;
            this.record = tableRows[index];
        }

        //如何准备判读是否需要更新？----待优化

        //--- 1.业务组setMeta 会更新meta; 
        //----2.业务调用表格API 会更新record
        //----3.业务修改孙表 会更新孙表数据
        // 更新下模板 有个问题是 给侧拉设置setQueryCondition
        // 业务组依靠修改引用 修改了meta
        // this.formStore.setMeta(this.formMeta, this.formMainCode);
        //将外部更新的数据 同步到孙表中
        //this.saveGrandTableData();

        //将外部更新数据 同步到表格表格中
        this.setFormMeta();
        this.updateSideBox(false, tableRows.length, true);

    }

    componentWillUnmount() {
        // 清理内存
        this.formStore = null;
        window.removeEventListener("resize", this.calcSideBoxWidth);
    }

    //设置表单模板
    setFormMeta = () => {
        let { status, mainCode, store } = this.props;
        let meta = store.getStore('meta');
        let destEditAreaCode = meta && meta.gridrelation && meta.gridrelation[mainCode].destEditAreaCode;
        let formMainCode = '';
        let formMeta = { formrelation: {} };
        if (isArray(destEditAreaCode)) {
            formMainCode = destEditAreaCode[0];
            formMeta.formrelation[formMainCode] = [];
            destEditAreaCode.forEach((item, index) => {
                formMeta[item] = {
                    ...meta[item],
                    items: [],
                };
                if (index !== 0) {
                    formMeta.formrelation[formMainCode].push(item);
                }
                let newItems = [];
                meta[item].items.forEach(element => {
                    let newItem = Object.assign({}, element);
                    if (isFunction(element.render)) {
                        let oldRender = element.render;
                        if (!newItem.renderStatus) { newItem.renderStatus = 'edit' };
                        let isEditRender = newItem.renderStatus === 'edit' && status === 'edit';
                        let isBrowseRender = newItem.renderStatus === 'browse' && status === 'browse';
                        // 判断显示render状态
                        if (isEditRender || isBrowseRender) {
                            newItem.render = (attrcode, values) => {
                                return oldRender(
                                    values[attrcode],
                                    {
                                        rowid: this.record.rowid,
                                        status: this.record.status,
                                        values: values,
                                    },
                                    this.index,
                                );
                            }
                        } else {
                            newItem.render = null;
                        }
                    }
                    newItems.push(newItem);
                });
                formMeta[item].items = newItems;
            });
        }
        this.formMeta = formMeta;
        this.formMainCode = formMainCode;
        if (this.formMainCode) {
            this.formStore.setMeta(this.formMeta, this.formMainCode);
        }

    };

    // 增行按钮函数
    handleAdd = () => {
        let { config, mainCode, store } = this.props;
        let { modelAddRow, modelAddDefaultValue } = config;

        //增行之前同步孙表数据
        this.saveGrandTableData();

        // 侧拉增行默认值
        const defaultValue = isFunction(modelAddDefaultValue) && modelAddDefaultValue();
        store.addTableRow({ defaultValue });
        let tableRows = store.getViewData();
        let length = tableRows.length;
        this.index = length - 1;
        this.record = tableRows[this.index] || {}; //defaultValue

        // 侧拉增行后的回掉  index 2005给业务组的是上一行的index
        isFunction(modelAddRow) && modelAddRow(mainCode, this.index - 1, { afterAddRowid: this.record.rowid, ...this.record }, store);

        this.updateSideBox(false, length);
    };

    // 删行按钮函数
    handleDel = () => {
        let { config, mainCode, store } = this.props;

        let { modelDelRow, grandGroup } = config;
        let tableRows = store.getViewData();
        let length = tableRows.length;

        // 兼容筛选时的侧拉
        // tableScope.filterAllData &&
        //     (currentModelIndex = rows.findIndex(
        //         (row) =>
        //             row.rowid === tableScope.filterAllData[modelIndex].rowid
        //     ));

        // 删除前，当前位置的行的rowid
        let beforeDelRowid = tableRows[this.index].rowid;

        if (length === 1) {
            //若只有一行，则关闭侧拉
            store.closeSideBox();
        } else {
            //若为最后一行，删除后侧拉显示上一行; 否则显示下一行
            if (this.index === length - 1) {
                this.index = length - 2;
            } else {
                //this.index ++;
            }
            this.record = tableRows[this.index] || {};
            //更新侧拉数据
            this.updateSideBox(false, length - 1);
        }

        //删除表格行
        store.deleteTableRows({ rowKeyValues: beforeDelRowid });
        //删除孙表数据
        // if (isArray(grandGroup)) {
        //     this.deleteGrandTableByRowid(beforeDelRowid);
        // }

        isFunction(modelDelRow) && modelDelRow(mainCode, { beforeDelRowid });
    };

    pageButtonClick = name => {
        let { store, config } = this.props;
        let tableData = store.getViewData();
        let { sideRowChange } = config;
        let length = tableData.length;
        switch (name) {
            case 'first':
                if (this.index !== 0) {
                    this.index = 0;
                }
                break;
            case 'prev':
                if (this.index !== 0) {
                    this.index = this.index - 1;
                }
                break;
            case 'next':
                if (this.index !== length - 1) {
                    this.index = this.index + 1;
                }
                break;
            case 'end':
                if (this.index !== length - 1) {
                    this.index = length - 1;
                }
                break;
        }

        //数据同步到孙表数据结构中
        this.saveGrandTableData();


        this.record = tableData[this.index] || {};
        this.updateSideBox(false, length);
        isFunction(sideRowChange) && sideRowChange(this.record);

    };

    //更新孙表数据
    saveGrandTableData = () => {
        let { config, store } = this.props;
        let { grandGroup } = config;

        let rowid = this.record.rowid;
        let grandTableData = store.getStore('grandTable');
        let grandTableStore = store.getStore('grandTableStore');
        let grandValidateFailed = store.getCache('grandValidateFailed') || {};
        isArray(grandGroup) &&
            grandGroup.forEach(item => {
                let currentStore = grandTableStore[item.grandTableId];
                let grandData = currentStore.getData();
                if (!grandTableData[rowid]) grandTableData[rowid] = {};
                grandTableData[rowid][item.grandTableId] = { rows: grandData };
                // 同步信息
                const validateFailed = grandValidateFailed[rowid] || {};
                grandValidateFailed[rowid] = JSON.parse(JSON.stringify(currentStore.getCache('validateFailed') || {}));
            });
        // console.log(grandValidateFailed);
        store.setCache('grandValidateFailed', grandValidateFailed);
        store.setStore('grandTable', grandTableData, false);
    };

    //删除孙表数据
    deleteGrandTableByRowid = rowid => {
        let { config, store } = this.props;
        let { grandGroup } = config;

        let grandTableData = store.getStore('grandTable');
        isArray(grandGroup) &&
            grandGroup.forEach(item => {
                delete grandTableData[rowid][item.grandTableId];
                if (Object.keys(grandTableData[rowid]).length === 0) {
                    delete grandTableData[rowid];
                }
            });
        store.setStore('grandTable', grandTableData, false);
    };

    //更新侧拉数据
    updateSideBox = (initMeta, length, notUpdateTable) => {
        let { status, config, store } = this.props;
        let { grandGroup } = config;
        let { buttonDisabled } = this.state;
        if (length === 1) {
            buttonDisabled = new Array(true, true, true, true);
        } else if (this.index === length - 1) {
            buttonDisabled = new Array(false, false, true, true);
        } else if (this.index === 0) {
            buttonDisabled = new Array(true, true, false, false);
        } else {
            buttonDisabled = new Array(false, false, false, false);
        }
        //更新标题
        this.setState({
            index: this.index,
            buttonDisabled,
        });

        store.setTableProps('currentIndex', store.getTrueRowIndex({ rowIndex: this.index }, 'normal', 'filter'), false);
        store.setStore(['tableSideBox', 'index'], this.index, false);
        store.setStore(['tableSideBox', 'record'], this.record, false);

        //设置表单数据
        //formStore.reset();
        let formValue = this.record.values;
        // disabled等属性的重置
        let formMetaItemsObj = {};
        (this.formStore.getMetaItems(this.formMainCode) || []).forEach(item => {
            item && this.formStore.setItemAttribute(item.attrcode, 'disabled', !!item.disabled, false);
        });
        // 先清空所有的值  再重新设置 避免切换页签时 值出现缓存情况 --bbq
        this.formStore.emptyAllFormValue();
        this.formStore.setAllFormValue(formValue);
        // 处理一下disabled等属性
        for (let key in formValue) {
            let formItem = formValue[key];
            isObject(formItem) && isBoolean(formItem.disabled) && this.formStore.setItemAttribute(key, 'disabled', formItem.disabled, false);
        }

        this.formStore.setStatus(status);

        //设置孙表数据
        let rowid = this.record.rowid;
        let grandTableData = store.getStore('grandTable');
        let grandTableStore = store.getStore('grandTableStore');
        let grandValidateFailed = store.getCache('grandValidateFailed') || {};
        // console.log(grandValidateFailed, 'grandValidateFailedgrandValidateFailedgrandValidateFailed');
        isArray(grandGroup) &&
            grandGroup.forEach(item => {
                let grandStore = grandTableStore[item.grandTableId];
                //只有侧拉初始化的时候 才取grandTableData更新孙表 否则直接取store中的数据
                //侧拉展开的时候 业务修改孙表 全部调用孙表API
                //孙表没有展开 才向grandTableData中存数据
                if (!notUpdateTable) {
                    if (grandTableData[rowid] && grandTableData[rowid][item.grandTableId]) {
                        let grandData = grandTableData[rowid][item.grandTableId];
                        if (grandData && grandData.rows) grandStore.setData({ data: grandData.rows });
                    } else {//不存在该孙表数据，为新增行
                        grandStore.setData({ data: [] });
                        if (!grandTableData[rowid]) grandTableData[rowid] = {};
                        grandTableData[rowid][item.grandTableId] = { rows: [] };
                    }
                }

                // 记录校验状态
                const validateFailed = grandValidateFailed[rowid];
                if (grandStore && validateFailed) {
                    // for (let tableId in validateFailed) {
                    const table = validateFailed[item.grandTableId];
                    // let isRefresh = false;
                    if (table) {
                        // 缓存状态
                        grandStore.setCache('validateFailed', validateFailed);
                        for (let tabId in table) {
                            const rows = table[tabId];
                            if (rows) {
                                for (let rowKeyValue in rows) {
                                    if (rowKeyValue) {
                                        let row = rows[rowKeyValue];
                                        if (row) {
                                            for (let attrcode in row) {
                                                // debugger;
                                                grandStore.setCellProps(rowKeyValue, attrcode, { validated: false }, false);
                                            }
                                        }
                                        // isRefresh = true;
                                    }
                                }

                            }
                        }
                        // isRefresh && store.refresh();
                    }
                    // }
                }

                initMeta && grandStore.setStore('meta', store.getStore('meta'));
            });
    };

    //注册侧拉按钮
    getButtons = () => {
        let { store, index, data, config = {}, mainCode, status } = this.props;
        let { modelFooter, hideModelSave, modelSave, hideAdd, modelAddRowBefore, hideDel, modelDelRowBefore } = config;
        let json = this.state.json;
        return (
            <span className="card-table-modal-header-btns">
                <span className="card-table-modal-header-customer">
                    {isFunction(modelFooter) && modelFooter(mainCode, this.index, this.record, store)}
                </span>
                {/* 整单保存 */}
                <Button
                    fieldid="tableModel-save"
                    style={{ display: (hideModelSave || status === 'browse') ? 'none' : 'inline-block' }}
                    className="btn-save btn-card-table-model"
                    onClick={() => {
                        isFunction(modelSave) && modelSave(this.index, store);
                    }}
                >
                    {json['cardTable003']}
                </Button>
                {/* 增行 */}
                <Button
                    fieldid="tableModel-add"
                    className="btn-add btn-card-table-model"
                    style={{ display: (hideAdd || status === 'browse') ? 'none' : 'inline-block' }}
                    onClick={async () => {
                        if (isFunction(modelAddRowBefore)) {
                            let isContinue = await modelAddRowBefore(mainCode, this.index);
                            if (isContinue) {
                                this.handleAdd();
                            }
                        } else {
                            this.handleAdd();
                        }
                    }}
                >
                    {json['cardTable004']}
                </Button>
                {/* 删行 */}
                <Button
                    fieldid="tableModel-del"
                    className="btn-del btn-card-table-model"
                    style={{ display: (hideDel || status === 'browse') ? 'none' : 'inline-block' }}
                    onClick={async () => {
                        if (isFunction(modelDelRowBefore)) {
                            let isContinue = await modelDelRowBefore(mainCode, this.index, this.record, store);
                            if (isContinue) {
                                this.handleDel();
                            }
                        } else {
                            this.handleDel();
                        }
                    }}
                >
                    {json['cardTable005']}
                </Button>
            </span>
        );
    };

    //关闭侧拉
    sideBoxClose = () => {
        let { onClose, config, store } = this.props;
        let { modelClose } = config;

        this.saveGrandTableData();
        
        // 注意转换index
        // store.setTableProps('currentIndex', this.index, false);
        store.setStore(['tableSideBox', 'index'], -1, false);
        store.setStore(['tableSideBox', 'record'], null, false);

        isFunction(onClose) && onClose();
        isFunction(modelClose) && modelClose();
    };

    //表单编辑后，保存表单数据到子表行
    formAfterEvent = prams => {
        let { areaId, attrcode, curMetaItem, value, oldValue, allValue, singleFormCode, componentValue } = prams;

        let { config, mainCode, store } = this.props;
        let { onAfterEvent } = config;

        this.record.values = allValue;
        if (this.record.status === ROW_STATUS.origin) {
            this.record.status = ROW_STATUS.edit;

        }
        // 根据rowid 更新表格数据
        let rows = store.setRows({ rows: [this.record] });
        this.record = this.record || (rows ? rows[0] : {});

        isFunction(onAfterEvent) &&
            onAfterEvent({
                moduleId: mainCode,
                record: this.record,
                rowKey: 'rowid',
                rowKeyValue: this.record.rowid,
                rowIndex: this.index,
                attrcode: attrcode,
                value: value,
                oldValue: oldValue,
                componentValue,
                isTriggerModal: true,
                //event,
                column: curMetaItem,
                //triggerType
            }, store);
    };

    formBeforeEvent = async (moduleId, attrcode, value, values, column) => {
        let { config, mainCode } = this.props;
        let { onBeforeEvent } = config;
        if (isFunction(onBeforeEvent)) {
            return (await onBeforeEvent({
                moduleId: mainCode,
                record: this.record,
                rowKey: 'rowid',
                rowKeyValue: this.record.rowid,
                rowIndex: this.index,
                attrcode: attrcode,
                value: value,
                isTriggerModal: true,
                //event,
                column,
                //triggerType
            })) !== false;
        } else {
            return true;
        }
    };

    render() {
        let { show, config, store, mainCode, renderItems, getHotKeyConfig, cardTableComponentUpdate, langJson = {}, onCellHyperlinkTo } = this.props;
        let { sideTitle, grandGroup, grandTableMainId = '', modalCustomerArea } = config;
        let { json, clientWidth } = this.state;
        let title = isFunction(sideTitle)
            ? sideTitle(this.state.index)
            : `${json['cardTable006']} ${this.state.index + 1}`;
        let grandTableStore = store.getStore('grandTableStore');

        console.log('clientWidth:', clientWidth)

        return (
            <Sidebox
                className={'card-table-sidebox'}
                mask={true}
                show={show}
                title={title}
                showButton={true}
                buttonDisabled={this.state.buttonDisabled}
                onClose={this.sideBoxClose}
                maskClose={this.sideBoxClose}
                buttonClick={this.pageButtonClick}
                //propContainer={}
                width={clientWidth > 1100 ? '1100px' : clientWidth + 'px'}
                getHeaderBtns={this.getButtons}
            >
                {/* 使用表单创建侧拉表头 */}
                <div fieldid={getSysFieldid(`${mainCode}_form-area`)}>
                    <Form
                        //meta={this.formMeta}
                        name={this.formMainCode}
                        //status={status}
                        config={{
                            onAfterEvent: this.formAfterEvent,
                            onBeforeEvent: this.formBeforeEvent,
                        }}
                        store={this.formStore}
                    //init={}
                    />
                </div>
                {/* 使用TabsTable创建侧拉表体 */}
                {isArray(grandGroup) && (
                    <MultiTabsTable
                        grandGroup={grandGroup}
                        meta={store.getStore('meta')}
                        // 传递孙表的多语
                        langJson={langJson}
                        grandTableMainId={grandTableMainId}
                        grandTableStore={grandTableStore}
                        grandTableConfig={this.grandTableConfig}
                        renderItems={renderItems}
                        getHotKeyConfig={getHotKeyConfig}
                        cardTableComponentUpdate={cardTableComponentUpdate}
                        isCalcHeight={isFunction(modalCustomerArea) ? false : true}
                        onCellHyperlinkTo={onCellHyperlinkTo}
                    />
                )}

                {isFunction(modalCustomerArea) && modalCustomerArea()}
            </Sidebox>
        );
    }
}
