/*
 * @Descripttion: 
 * @version: 
 * @Author: bbq
 * @Date: 2020-07-31 18:00:00
 * @LastEditors: bbq
 * @LastEditTime: 2020-10-15 13:52:04
 */
import React, { Component } from "react";
import ReactDOM from 'react-dom';
import TabsTable from "../TabsTable";
import { PubSub } from '@platform/api';

const AREACHANGECOMPLETE = 'areaChangeComplete';

export default class MultiTabsTable extends Component {
    constructor(props) {
        super(props);
        //模板
        this.multiCodeArr = null;
        this.adaptionHeight = false;
        this.isCalcHeight = false;
        this.grandTable = null;
        this.pubsub_token = null;

        this.state = {
            grandTableHeight: ''
        }
    }

    componentWillMount() {
        this.getMultiMeta();

    }

    componentDidMount() {
        let { grandTableMainId, grandGroup } = this.props;
        if (grandTableMainId && Array.isArray(this.multiCodeArr) && this.multiCodeArr.length === 1) {
            this.adaptionHeight = true;
        } else if (Array.isArray(grandGroup) && grandGroup.length === 1) {
            this.adaptionHeight = true;
        }
      
        this.pubsub_token = PubSub.subscribe(AREACHANGECOMPLETE, this.handlePubSub);

        window.addEventListener("resize",this.calcGrandTableHeight);
        
    }

    handlePubSub=()=>{
        this.calcGrandTableHeight();
    }

    calcGrandTableHeight=()=>{
        if(this.adaptionHeight && this.props.isCalcHeight){
            //currentTop计算不准确
            let currentTop = ReactDOM.findDOMNode(this.grandTable)?.getBoundingClientRect().top;
            let grandTableHeight = document.body.offsetHeight - currentTop - 80 -8;
            this.isCalcHeight = true;
            this.setState({
                grandTableHeight
            })
        }
        
    }

    componentDidUpdate() {
        if (!this.isCalcHeight && this.grandTable) {
            this.isCalcHeight = true;
            this.calcGrandTableHeight();
        }
    }

    componentWillUnmount(){
        PubSub.unsubscribe(this.pubsub_token);
        window.removeEventListener("resize",this.calcGrandTableHeight);
    }

    getMultiMeta = () => {
        let { meta, grandTableMainId } = this.props;
        //孙表横向多页签的主表区域编码
        let containerrelation = meta.containerrelation;
        if (
            grandTableMainId &&
            containerrelation &&
            Array.isArray(containerrelation[grandTableMainId])
        ) {
            this.multiCodeArr = containerrelation[grandTableMainId];
        }
    };

    render() {
        let {
            grandTableStore,
            grandTableMainId,
            grandGroup,
            grandTableConfig,
            renderItems,
            getHotKeyConfig,
            cardTableComponentUpdate,
            langJson,
            onCellHyperlinkTo,
        } = this.props;

        let { grandTableHeight } = this.state;

        if (this.adaptionHeight && grandTableHeight) {
            for (let grandId in grandTableConfig) {
                if (!grandTableConfig[grandId]) grandTableConfig[grandId] = {}
                grandTableConfig[grandId].height = grandTableHeight;
            }
        }

        return (
            <div className="grandTable-wrapper" ref={(dom => this.grandTable = dom)}>
                {grandTableMainId && Array.isArray(this.multiCodeArr) ?
                    this.multiCodeArr.map((item, index) => {
                        let mainCode = item[0];
                        return (
                            <TabsTable
                                key={index}
                                //单来源页签属性
                                mainCode={mainCode}
                                renderItems={renderItems}
                                getHotKeyConfig={getHotKeyConfig}
                                cardTableComponentUpdate={cardTableComponentUpdate}
                                onCellHyperlinkTo={onCellHyperlinkTo}

                                //多页签store及多页签config
                                tabsStore={grandTableStore}
                                tabsConfig={grandTableConfig}
                                metaTabs={item}
                                isGrandTable={true}
                                langJson={langJson}
                                //孙表标志
                                isGrandson={true}
                            />
                        );
                    }) : (
                        grandGroup.map((item, index) => {
                            let moduleId = item.grandTableId;
                            return <TabsTable
                                key={index}
                                //单来源页签属性
                                mainCode={moduleId}
                                renderItems={renderItems}
                                getHotKeyConfig={getHotKeyConfig}
                                cardTableComponentUpdate={cardTableComponentUpdate}

                                onCellHyperlinkTo={onCellHyperlinkTo}

                                //多页签store及多页签config
                                tabsStore={{ [moduleId]: grandTableStore[moduleId] }}
                                tabsConfig={{ [moduleId]: grandTableConfig[moduleId] }}
                                metaTabs={[moduleId]}
                                isGrandTable={true}
                                langJson={langJson}
                                //孙表标志
                                isGrandson={true}
                            />
                        })
                    )

                }
            </div>
        )
    }
}
